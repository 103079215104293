import colors from '../common/colors'
import { navigate } from 'svelte-navigator'

export function expandInfobox(event) {
  const customerId = event.target.classList[0]
  const infoboxExpand = document.getElementById(`${customerId}-expand`)
  let infoboxExpandChevron = document.getElementsByClassName(`${customerId} chevron`)[0]

  if (infoboxExpandChevron.className.includes('left')) {
    infoboxExpandChevron.className = infoboxExpandChevron.className.replace('left', 'bottom')
  } else {
    infoboxExpandChevron.className = infoboxExpandChevron.className.replace('bottom', 'left')
  }

  if (infoboxExpand.style.display === 'block') {
    infoboxExpand.style.display = 'none'
  } else {
    infoboxExpand.style.display = 'block'
  }
}

export function linkToChartPage(event) {
  navigate(`/customer/charts?customerId=${event.currentTarget.id}`)
}

export const handleMarkerClick = (google, map, marker, infowindow, customerData) => {
  const info =
    `<div id='${customerData.customerId}' class=infobox-wrapper><p>Customer ID: ` +
    customerData.customerId +
    '</p>' +
    '<p>From grid: ' +
    customerData.fromgrid +
    ' kWh</p>' +
    '<p>To grid: ' +
    customerData.togrid +
    ' kWh</p></div>' +
    `<div class=infobox-additional-info-wrapper><span class=infobox-hr></span>` +
    `<span class='${customerData.customerId} chevron left'/></div>` +
    `<div id=${customerData.customerId}-expand class='infobox-expand-hide infobox-wrapper'><p>Total consumption: ` +
    customerData.household +
    ' kWh</p>' +
    '<p>Total generation: ' +
    customerData.totalsolar +
    ' kWh</p>' +
    '<p>Solar consumption: ' +
    customerData.fromsolar +
    ' kWh</p></div></div>'
  infowindow.setContent(info)

  marker.addListener('click', function () {
    infowindow.open(map, marker)
    setTimeout(() => {
      const infoboxChevrons = document.querySelectorAll('.chevron.left')
      const infoboxes = document.querySelectorAll('.infobox-wrapper')
      if (infoboxes.length > 0) {
        infoboxes.forEach((infobox) => {
          infobox.addEventListener('click', linkToChartPage)
        })
        infoboxChevrons.forEach((chevron) => {
          chevron.addEventListener('click', expandInfobox)
        })
      }
    }, 500)
  })

  google.maps.event.addListener(map, 'click', function () {
    if (infowindow) {
      infowindow.close(map, infowindow)
    }
  })
}

export function filterCircleOnMap(map, markers) {
  for (var i = 0; i < markers.length; i++) {
    markers[i].setMap(map)
  }
}

export function setCircle(google, color, grid) {
  return {
    path: google.maps.SymbolPath.CIRCLE,
    fillOpacity: 0.45,
    fillColor: color,
    strokeOpacity: 0.6,
    strokeColor: color,
    strokeWeight: 1,
    scale: grid * 3
  }
}

export function setCircleMarker(google, map, lat, lng, circle, zIndex) {
  return new google.maps.Marker({
    position: new google.maps.LatLng(lat, lng),
    icon: circle,
    zIndex: zIndex,
    map: map
  })
}

export function countDevicesInArea(map, allMarkers, devicesInArea) {
  const currentMapBounds = map.getBounds()
  allMarkers.map((marker) => {
    if (currentMapBounds.contains(marker.position)) {
      devicesInArea.push(marker)
    }
  })
  return devicesInArea
}

export function setCustomControl() {
  setTimeout(() => {
    document.getElementById('gridFilterButtonsWrapper').style.display = 'block'
    document.getElementById('displayedDeviceInfo').style.display = 'block'
  }, 200)
}

export function setVisibilityOfGridMarkers(fromGridMarkers, toGridMarkers, visibility) {
  fromGridMarkers.forEach((marker) => marker.setVisible(visibility))
  toGridMarkers.forEach((marker) => marker.setVisible(visibility))
}

export const setMarkers = (
  customer,
  google,
  map,
  fromGridMarkers,
  toGridMarkers,
  marker,
  allMarkers,
  bounds,
  customersData,
  timeRangeFilter,
  fromGridMarkerLocation,
  toGridMarkerLocation
) => {
  const latitude = customer.location.Pc
  const longitude = customer.location.Vc
  const infowindow = new google.maps.InfoWindow()
  let latAndLong = new google.maps.LatLng(latitude, longitude)
  const customerData = customersData.find(({ customerId }) => customerId === customer.customerId)

  let toGrid = 0
  let fromGrid = 0
  if (customerData) {
    toGrid = customerData.togrid
    fromGrid = customerData.fromgrid
  }

  if (timeRangeFilter > 1) {
    toGrid = toGrid / 5
    fromGrid = fromGrid / 5
  }

  const toGridCircle = setCircle(google, colors.orange, toGrid)
  const fromGridCircle = setCircle(google, colors.blue, fromGrid)
  let toGridMarker
  let fromGridMarker

  if (fromGrid > toGrid) {
    fromGridMarker = setCircleMarker(google, map, latitude, longitude, fromGridCircle, 1)
    toGridMarker = setCircleMarker(google, map, latitude, longitude, toGridCircle, 2)
    fromGridMarkers.push(fromGridMarker)
    toGridMarkers.push(toGridMarker)
    fromGridMarkerLocation[latAndLong] = fromGridMarker
    toGridMarkerLocation[latAndLong] = toGridMarker
  } else {
    toGridMarker = setCircleMarker(google, map, latitude, longitude, toGridCircle, 1)
    fromGridMarker = setCircleMarker(google, map, latitude, longitude, fromGridCircle, 2)
    toGridMarkers.push(toGridMarker)
    fromGridMarkers.push(fromGridMarker)
    fromGridMarkerLocation[latAndLong] = fromGridMarker
    toGridMarkerLocation[latAndLong] = toGridMarker
  }

  marker = new google.maps.Marker({
    position: new google.maps.LatLng(latitude, longitude),
    zIndex: 999,
    icon: {
      url:
        'https://firebasestorage.googleapis.com/v0/b/dius-powersensor-portal-qa.appspot.com/o/mapPin.svg?alt=media&token=9a2b43ae-80c7-4ec7-a4a2-ce767ee1a674'
    }
  })

  if (customerData) {
    handleMarkerClick(google, map, marker, infowindow, customerData)
  }

  bounds.extend(marker.position)
  allMarkers.push(marker)
}
