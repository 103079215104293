import { writable } from 'svelte/store'

const createFilterStore = () => {
  const { subscribe, update } = writable({
    search: []
  })

  return {
    subscribe,
    updateSearchResult: (searchResult) => update((results) => ({ ...results, search: searchResult }))
  }
}

export const results = createFilterStore()
