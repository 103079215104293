<script>
  import { isEmpty } from 'ramda'
  import WiFi from './cards/WiFi.svelte'
  import Battery from './cards/Battery.svelte'
  import BLE from './cards/BLE.svelte'
  import FieldMeasurement from './cards/FieldMeasurement.svelte'
  import Firmware from './cards/Firmware.svelte'

  export let deviceData = []
  export let deviceType
</script>

<style>
  .dashboard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
</style>

{#if !isEmpty(deviceData)}
  <div class="dashboard">
    <WiFi {deviceData} />
    {#if deviceType === 'sensor'}
      <Battery {deviceData} />
      <BLE {deviceData} />
      <FieldMeasurement {deviceData} />
    {/if}
    <Firmware {deviceData} />
  </div>
{/if}
