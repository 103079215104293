// as a guide array indexes denote importance in descending order
// (0 = most important, 3 = least important)
export const orderedSensorTags = [
  ['batt', 'rssi', 'rasi', 'rase', 'rasu', 'rafs', 'rafu', 'rars', 'imag', 'ivol', 'irsi', 'upis', 'upup', 'upau'],
  ['ptji', 'sahp', 'nwkc', 'nwku', 'rntm', 'watt', 'sats', 'satp'],
  ['tsr1', 'tsr2', 'upld', 'upct', 'puls', 'role'],
  ['am00', 'am01', 'am02', 'am03', 'am04', 'am05', 'sens', 'ktmp', 'ptms', 'woui']
]

export const orderedPlugTags = [
  ['rssi', 'upis', 'upts', 'uppt', 'cprr', 'hwrv', 'upau', 'upcf'],
  ['pbws', 'pbwa', 'pbc', 'uptm', ' cbvo', 'cbam', 'cbwa', 'ctvo', 'ctan', 'ctwa'],
  ['pbpd', 'pbvo', 'ptmp', 'ctmp', 'init'],
  ['pbts', 'pbtp', 'upnn', 'upbu']
]
