<script>
  import { navigate } from 'svelte-navigator'
  import { resetPassword } from '../../auth'

  let initalState = {
    username: '',
    usernameError: ''
  }

  let state = Object.assign({}, initalState)

  const resetErrors = () => {
    state.usernameError = ''
    state.passwordError = ''
  }

  const handleResetPassword = () => {
    resetErrors()
    resetPassword(state.username)
      .then(() => navigate('/', { replace: true }))
      .catch((error) => {
        console.log(error)
        state.usernameError = error.message
      })
  }

  const handleClearForm = () => {
    state = Object.assign({}, initalState)
  }
</script>

<style>
  .container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgb(33, 54, 108);
  }
  .center-image {
    position: fixed;
    left: 50%;
    margin: 100px 0 0 -133px;
  }
  .centered {
    position: fixed;
    left: 50%;
    margin: 200px 0 0 -250px;
  }
  .box {
    width: 500px;
    border-radius: 8px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background: #fff;
  }
  input {
    display: block;
    margin: 20px 0 0 0;
  }
  .formControls {
    margin-top: 20px;
  }
  h1 {
    /* TODO: extract color */
    color: rgb(23, 42, 90);
  }
  .buttonGroup {
    float: right;
  }
  .error {
    font-size: 10px;
    color: #ff0000;
  }
  button {
    margin: 20px 0 0 10px;
  }
  @media screen and (max-width: 500px) {
    .center-image {
      position: fixed;
      left: 50%;
      margin: 100px 0 0 -133px;
    }
    .centered {
      position: fixed;
      left: 50%;
      margin: 200px 0 0 -45%;
    }
    .box {
      width: 80%;
      border-radius: 8px;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
      background: #fff;
    }
    button {
      margin: 20px 0 0 10px;
    }
  }
</style>

<div class="container">
  <img class="center-image" alt="Powersenor" src="/images/powersensor.png" />

  <div class="box centered">
    <h1>
      <strong>Reset Password</strong>
    </h1>
    <input type="text" bind:value={state.username} placeholder="enter your username" />
    <span class="error">{state.usernameError}</span>
    <div class="formControls">
      <div class="buttonGroup">
        <button on:click={handleClearForm}>Clear</button>
        <button on:click={handleResetPassword}>Reset Password</button>
      </div>
    </div>
  </div>
</div>
