<script>
  import { getUsernameFromToken, getExpiryFromToken } from './utils/token'
  export let loginError
  export let loggedIn
  export let handleLogout
  export let token
  $: username = token ? getUsernameFromToken(token) : null
  $: tokenExpiryTimestamp = token ? getExpiryFromToken(token) : null
  $: tokenExpiry = tokenExpiryTimestamp ? Math.floor(Number(tokenExpiryTimestamp - Date.now() / 1000)) : null

  const interval = setInterval(() => {
    if (tokenExpiry !== null) {
      if (tokenExpiry <= 0) {
        clearInterval(interval)
        handleLogout()
      } else {
        tokenExpiry -= 1
      }
    }
  }, 1000)

  const formatTime = (remainingSeconds) => {
    const minutes = Math.floor(remainingSeconds / 60)
    let seconds = remainingSeconds % 60
    if (seconds < 10) {
      seconds = '0' + seconds
    }
    return `${minutes}:${seconds}s`
  }
</script>

<style>
  .font-red {
    color: red;
  }

  .flex {
    display: flex;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .logout-button {
    background-color: var(--primary_blue_secondary);
  }
</style>

{#if loginError === true}
  <span class="font-red">invalid username or password</span>
{:else if loggedIn === true}
  <span class="flex">
    <span class="flex-column">
      <div>
        Logged in as
        <strong>{username}</strong>
      </div>
      <div>
        Token expires in:
        <strong>{formatTime(tokenExpiry)}</strong>
      </div>
    </span>
    <button class="logout-button" on:click={handleLogout}>Logout</button>
  </span>
{/if}
