<script>
  import { apiLogin } from './services/auth'

  export let handleLoginSuccess = null
  export let handleLoginFailure = null

  let username = ''
  let password = ''

  let loading

  const onLogin = async () => {
    if (!username || !password) {
      return
    }

    loading = true
    try {
      const response = await apiLogin(username, password)
      const { jwt, admin_url } = response
      handleLoginSuccess(jwt, admin_url)
    } catch (e) {
      handleLoginFailure()
    }
    loading = false
  }
</script>

<style>
  .login-input {
    width: 14rem;
  }

  .login-button {
    background-color: var(--primary_blue_secondary);
  }
</style>

<form on:submit|preventDefault={onLogin}>
  <input class="login-input" type="text" placeholder="username" disabled={loading} bind:value={username} />
  <input class="login-input" type="password" placeholder="password" disabled={loading} bind:value={password} />
  <button class="login-button">Login</button>
</form>
