<script>
  import { filters } from '../../store/filters'

  const filterTimeOptions = [1, 7, 30]
  const setTimePeriod = (timeOption) => () => filters.updateTimeRangeFilter(timeOption)
</script>

<div class="layout__align_right toolbar__date_filter toolbar__button_layout toolbar__button_group">
  {#each filterTimeOptions as timeOption}
    <button
      class={timeOption === $filters.timeRangeFilter ? 'toolbar__group_button_selected' : ''}
      on:click={setTimePeriod(timeOption)}>
      {timeOption === 1 ? '24 HOURS' : `${timeOption} DAYS`}
    </button>
  {/each}
</div>
