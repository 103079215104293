<script>
  export let deviceData = []
</script>

<style>
  .raw-data-list {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: rgb(23, 42, 90);
    color: white;
  }

  .raw-data-list-item {
    border-bottom: 1px solid lightgrey;
    line-height: 3rem;
    padding-left: 1rem;
  }
</style>

<ul class="raw-data-list">
  {#each deviceData as { tag, value, interval, priority }}
    <li class="raw-data-list-item">
      <span className="data-list-tag">{priority}: {tag}</span>
      <span className="data-list-detail">
        <span>{`${value}`}</span>
        <span>{interval}</span>
      </span>
    </li>
  {/each}
</ul>
