<script>
  const panelTypes = {
    INFO: 'info',
    ERROR: 'error'
  }

  export let mode = panelTypes.INFO
</script>

<style>
  .panel {
    font-size: 1.25rem;
    padding: 1rem;
    border-radius: 5px;
  }

  .error {
    color: red;
    border: 1px solid red;
  }

  .info {
    color: grey;
    border: 1px solid grey;
  }
</style>

<div class="panel {mode}" data-testid="panel">
  <slot />
</div>
