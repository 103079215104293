<script>
  import { onMount, onDestroy } from 'svelte'
  import Chart from 'chart.js'
  import { DateTime } from 'luxon'

  import { functions } from '../../firebase'
  import colors from '../../common/colors'
  import Panel from '../../common/components/Panel.svelte'
  import { userStore } from '../../store/user'

  export let selectedCustomer
  export let index

  const SOLARPLATFORM_API_HOST = 'api.cluster-1.powersensor.dius.com.au'
  const loadStatuses = {
    SUCCESS: 'sunccess',
    ERROR: 'error',
    LOADING: 'loading'
  }

  $: loadStatus = loadStatuses.LOADING
  $: liveData = []
  let chart, socket
  const { solarplatformAPIKey, solarplatformAPISharedSecret } = $userStore

  const startSocket = async (customerId) => {
    if (!customerId) return

    const {
      data: { token }
    } = await functions.httpsCallable('getAuthToken')({
      apiKey: solarplatformAPIKey,
      sharedSecret: solarplatformAPISharedSecret,
      customerId
    })

    socket = new WebSocket(`wss://${SOLARPLATFORM_API_HOST}/v3/household/live?bearer=${token}`)
    socket.onopen = () => console.log('websocket connection open')
    socket.onerror = (error) => console.log('got error:', error)
    socket.onclose = () => console.log('socket connection closed')

    socket.onmessage = function ({ data }) {
      const parsedData = JSON.parse(data)
      if (!chart) drawChart()
      loadStatus = loadStatuses.SUCCESS
      addData(parsedData)
      liveData = [...liveData, parsedData]
    }
  }

  const closeSocket = async () => {
    if (socket) await socket.close()
  }

  const drawChart = () => {
    if (chart) chart.destroy()
    if (!document.getElementById(`live-chart-${index}`)) return

    const ctx = document.getElementById(`live-chart-${index}`).getContext('2d')
    const config = {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Household',
            borderColor: colors.blue,
            backgroundColor: colors.blue,
            data: [],
            fill: false
          },
          {
            label: 'Solar',
            borderColor: colors.orange,
            backgroundColor: colors.orange,
            data: [],
            fill: false
          },
          {
            label: 'Net',
            borderColor: colors.darkred,
            backgroundColor: colors.darkred,
            data: [],
            fill: false
          }
        ]
      },
      options: {
        responsive: true,
        tooltips: {
          mode: 'index'
        },
        hover: {
          mode: 'index'
        },
        title: {
          display: true,
          text: selectedCustomer
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Time'
              }
            }
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Energy (kWh)'
              }
            }
          ]
        }
      }
    }

    chart = new Chart(ctx, config)
  }

  const addData = ({ household, solar, net, time }) => {
    const { labels, datasets } = chart.data
    labels.push(DateTime.fromISO(time).toFormat('HH:mm:ss'))
    datasets[0].data.push(household)
    datasets[1].data.push(Math.abs(solar))
    datasets[2].data.push(net)

    if (labels.length > 10) {
      labels.splice(0, 1)
      datasets.forEach((dataset) => dataset.data.splice(0, 1))
    }

    chart.update()
  }

  onMount(() => {
    setTimeout(() => {
      loadStatus = liveData.length > 0 ? loadStatuses.SUCCESS : loadStatuses.ERROR
    }, 40000)
    startSocket(selectedCustomer)
  })

  onDestroy(() => {
    closeSocket()
    if (chart) chart.destroy()
  })
</script>

<style>
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  .loading span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  .loading span:nth-child(2) {
    animation-delay: 0.2s;
  }

  .loading span:nth-child(3) {
    animation-delay: 0.4s;
  }
</style>

{#if loadStatus === loadStatuses.ERROR}
  <Panel mode="error">Error loading data for {selectedCustomer}</Panel>
{:else if loadStatus === loadStatuses.LOADING}
  <Panel>
    <div class="loading">
      Loading data for {selectedCustomer}
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  </Panel>
{/if}
<canvas id="live-chart-{index}" width="10" height="4" />
