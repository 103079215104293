import { writable } from 'svelte/store'

const createUserStore = () => {
  const { subscribe, set, update } = writable(null)

  return {
    subscribe,
    set: (user) => set(user),
    update: (user) => update(user),
    clear: () => set(null)
  }
}

export const userStore = createUserStore()
