import { auth } from './firebase'

export const logOut = () =>
  auth
    .signOut()
    .then(() => {
      console.log('done')
    })
    .catch((error) => {
      console.log(error)
    })

export const login = (username, password) => auth.signInWithEmailAndPassword(username, password)

export const resetPassword = (username) => auth.sendPasswordResetEmail(username)
