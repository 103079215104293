<script>
  import { navigate, useLocation } from 'svelte-navigator'

  const location = useLocation()
  const modes = [
    {
      path: '/customer/table',
      name: 'Table'
    },
    {
      path: '/customer/charts',
      name: 'Chart'
    },
    {
      path: '/customer/map',
      name: 'Map'
    }
  ]
  const defaultSelection = 'Table'

  let currentMode = modes[0]
  location.subscribe((loc) => {
    const possibleMode = modes.find(({ path }) => path.startsWith(loc.pathname))

    if (possibleMode) {
      currentMode = possibleMode
    } else {
      console.warn('Failed to match route:', loc)
      currentMode = defaultSelection
    }
  })
</script>

<style lang="scss">
  .toolbar__button_group button:not(:last-child) {
    margin-right: 0.2rem;
  }
</style>

<div class="layout__align_center toolbar__button_layout toolbar__button_group">
  {#each modes as mode}
    <button
      class={mode.name === currentMode.name ? 'toolbar__group_button_selected' : ''}
      on:click={() => navigate(mode.path)}>
      {mode.name}
    </button>
  {/each}
</div>
