<script>
  import { isEmpty } from 'ramda'
  import DeviceDiagnosticsData from './DeviceDiagnosticsData.svelte'
  import DeviceDiagnosticsCards from './DeviceDiagnosticsCards.svelte'
  import SearchBar from './SearchBar.svelte'
  import SuperAdminLogin from './SuperAdminLogin.svelte'
  import SuperAdminLoginDetails from './SuperAdminLoginDetails.svelte'
  import DataSourceSelection from './DataSourceSelection.svelte'
  import { getDiagnosticsForDeviceId } from './services/device'
  import { orderedSensorTags, orderedPlugTags } from './tags'
  import { deviceDiagnosticsDashboard, STORE_NAME } from '../../store/deviceDiagnosticsDashboard'
  import { getAdminUrlFromToken } from './utils/token'
  import { snapshotData } from './data'
  import { isLocalhost } from './utils/isLocalhost'

  let useLocalData = isLocalhost

  let deviceId = ''
  let deviceType
  let deviceData = []

  let token
  let adminUrl

  deviceDiagnosticsDashboard.subscribe((store) => {
    localStorage.setItem(STORE_NAME, JSON.stringify(store))
    token = store.token
    if (token) {
      adminUrl = getAdminUrlFromToken(token)
    }
  })

  let loggedIn = token !== null
  let loginError = false

  const handleSearch = async (value) => {
    deviceId = value
    if (token || useLocalData) {
      let data
      if (useLocalData) {
        data = snapshotData[value] || {}
      } else {
        data = await getDiagnosticsForDeviceId(token, adminUrl, deviceId)
      }
      if (isEmpty(data)) {
        deviceData = data
      } else {
        const parsedDeviceData = parseDeviceData(data).sort(orderByPriority)
        deviceData = parsedDeviceData
      }
    } else {
      console.error('invalid token')
    }
  }

  const handleLoginSuccess = (jwt, admin_url) => {
    loggedIn = true
    loginError = false
    deviceDiagnosticsDashboard.updateToken(jwt)
    token = jwt
    adminUrl = admin_url
  }

  const handleLoginFailure = () => {
    loginError = true
  }

  const getFormattedDataPointInterval = (utc1, utc2) => {
    if (utc1 && utc2) {
      return `(${utc2 - utc1}s)`
    } else {
      return ''
    }
  }

  const getPriorityForTag = (key) => {
    let priority = 999
    const orderedTags = deviceType === 'sensor' ? orderedSensorTags : orderedPlugTags

    for (const index in orderedTags) {
      if (orderedTags[index].includes(key)) {
        priority = (Number(index) + 1) * 100 + orderedTags[index].indexOf(key)
        break
      }
    }

    return priority
  }

  const parseDeviceData = (data) => {
    const satp = Object.keys(data).find((tag) => tag === 'satp')
    const sats = Object.keys(data).find((tag) => tag === 'sats')
    const batt = Object.keys(data).find((tag) => tag === 'batt')
    deviceType = (satp && sats) || batt ? 'sensor' : 'plug'

    return Object.entries(data).map(([tag, { utc1, utc2, value }]) => {
      const interval = getFormattedDataPointInterval(utc1, utc2)
      const priority = getPriorityForTag(tag)
      return { tag, interval, value, utc1, utc2, priority }
    })
  }

  const orderByPriority = (objA, objB) => {
    const { priority: a } = objA
    const { priority: b } = objB

    if (a < b) return -1
    if (a > b) return 1
    return 0
  }

  const handleLogout = () => {
    loggedIn = false
    deviceDiagnosticsDashboard.logout()
  }
</script>

<style>
  .device-dashboard {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 70px);
    padding-top: 50px;
  }

  .top-bar {
    padding-left: 15px;
    background-color: rgb(23, 42, 90);
    color: white;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 70px;
    max-height: 70px;
  }

  .content {
    display: flex;
    flex-grow: 1;
    height: 100%;
  }

  .content-left-panel {
    width: 25rem;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .content-main {
    width: 100%;
  }

  .header {
    font-size: 2rem;
  }

  .horizontal-spacing {
    padding-right: 2rem;
  }

  .user-input {
    display: flex;
  }
</style>

<div class="device-dashboard">
  <div class="top-bar">
    <div class="header">
      <span class="horizontal-spacing">Device ID: {deviceId ? deviceId : '_'}</span>
      <span>Type: {deviceId ? deviceType : '_'}</span>
    </div>
    <SuperAdminLoginDetails {loginError} {loggedIn} {handleLogout} {token} />
    <div class="user-input">
      <DataSourceSelection bind:useLocalData />
      {#if loggedIn || useLocalData}
        <SearchBar onSubmit={handleSearch} />
      {:else}
        <SuperAdminLogin {handleLoginSuccess} {handleLoginFailure} />
      {/if}
    </div>
  </div>
  <div class="content">
    {#if isEmpty(deviceData)}
      <div>{`No device data ${deviceId ? 'found for device id ' + deviceId : ''}`}</div>
    {:else}
      <div class="content-left-panel">
        <DeviceDiagnosticsData {deviceData} />
      </div>
      <div class="content-main">
        <DeviceDiagnosticsCards {deviceData} {deviceType} />
      </div>
    {/if}
  </div>
</div>
