<script>
  import { onMount, onDestroy } from 'svelte'
  import SvelteTable from 'svelte-table'
  import { pathOr } from 'ramda'

  import { firestore, functions } from '../../firebase'
  import { downloadCSV } from '/common/csv'
  import { userStore } from '../../store/user'

  export let providerId = null

  let unsubUsersCollection
  let unsubUsersStore
  const exportProviderCustomerCSV = functions.httpsCallable('exportProviderCustomerCSV')
  const createCustomers = functions.httpsCallable('createCustomers')

  let initalState = {
    csv: '',
    csvError: '',
    isSubmitting: false
  }

  let state = Object.assign({}, initalState)

  const providerLoaded = (querySnapshot) => {
    if (querySnapshot.exists) {
      const providerData = querySnapshot.data()
      rows = pathOr([], ['customers'], providerData)
    }
  }

  const isCSVValid = () => state.csv !== ''

  const canSubmit = () => isCSVValid()

  const clearErrors = () => {
    state.csvError = ''
  }

  unsubUsersStore = userStore.subscribe((user) => {
    if (!user) unsubUsersCollection()
  })

  onMount(async () => {
    unsubUsersCollection = await firestore.collection('users').doc(providerId).onSnapshot(providerLoaded)
  })

  onDestroy(unsubUsersStore)

  let rows = []

  const columns = [
    {
      key: 'edit',
      title: '',
      value: (value) => `<label class="html__checkbox-container">
                <input type="checkbox" ${value.edit ? 'checked="checked"' : ''} />
                <span class="html__checkmark"></span>
        </label>`
    },
    {
      key: 'customerId',
      title: 'Customer ID',
      value: (value) => value.customerId,
      sortable: true
    },
    {
      key: 'authorisationCode',
      title: 'Authorisation Code',
      value: (value) => value.authorisationCode,
      sortable: true
    }
  ]

  const handleClearCustomers = () => (state = Object.assign({}, initalState))

  const handleCreateCustomers = () => {
    clearErrors()
    if (!isCSVValid()) state.emailError = 'Invalid CSV'

    if (canSubmit()) {
      createCustomers({ providerId, customers: state.csv }).then((response) => {
        alert(response.data.message)
        handleClearCustomers()
      })
    } else {
      alert('Form is not valid')
    }
  }

  const handleDownloadCustomers = () => {
    exportProviderCustomerCSV({ providerId }).then((response) => downloadCSV(response.data, 'customers.csv'))
  }
</script>

<style>
  .customer-table {
    max-height: 500px;
    overflow-y: scroll;
  }
  .error {
    font-size: 10px;
    color: #ff0000;
  }
  button {
    margin-top: 15px;
  }
</style>

<div class="toolbar__header layout__flex_column layout__flex_container_bottom svelte__wrapper" />

<div class="box centered">
  <h1>Upload Customers</h1>
  <label for="csv">Customer CSV</label>
  <textarea name="csv" placeholder="Insert CSV" bind:value={state.csv} rows="5" />
  <span class="error">{state.csvError}</span>
  <button on:click={handleClearCustomers}>Clear</button>
  <button on:click={handleCreateCustomers} disabled={state.isSubmitting}>Create Customers</button>
  <br />
  <br />
  <h1>Download Customers</h1>
  <div class="customer-table">
    <SvelteTable
      {columns}
      {rows}
      classNameThead={'tableThead'}
      classNameTbody={'tableTbody'}
      classNameRow={'pointer'} />
  </div>
  <div>
    <button on:click={handleDownloadCustomers} disabled={state.isSubmitting}>Download CSV</button>
  </div>
</div>
