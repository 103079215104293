<script>
  import Card from '../Card.svelte'
  export let deviceData = []

  const MAX_VOLTS = 4.1
  const MIN_VOLTS = 3.4

  const normaliseVolts = (value) => {
    let normalisedValue = value
    if (normalisedValue > MAX_VOLTS) {
      normalisedValue = MAX_VOLTS
    } else if (normalisedValue < MIN_VOLTS) {
      normalisedValue = MIN_VOLTS
    }
    return normalisedValue
  }

  let alarmStatus
  let percentage
  let statusColor
  $: battData = deviceData.find((data) => data.tag === 'batt') || {}
  $: value = battData.value
  $: tag = battData.tag
  $: utc1 = battData.utc1
  $: utc2 = battData.utc2
  //Translate to percentage as 3.4V=0%, 4.1V=100%, linear in between
  $: percentage = (((normaliseVolts(Number(value)) - MIN_VOLTS) / (MAX_VOLTS - MIN_VOLTS)) * 100).toFixed(2)
  $: {
    if (isNaN(percentage)) {
      alarmStatus = '?'
      statusColor = 'orange'
    } else if (percentage > 50) {
      alarmStatus = 'OK'
      statusColor = 'green'
    } else if (percentage < 50 && percentage >= 20) {
      alarmStatus = '?'
      statusColor = 'orange'
    } else if (percentage < 20) {
      alarmStatus = 'X'
      statusColor = 'red'
    }
  }
</script>

<Card timestamp={utc2 || utc1} title="Battery">
  <div>
    {percentage}% -
    <span style={`color: ${statusColor}`}>{alarmStatus}</span>
  </div>
  <div>
    {#if value}
      {`Value: (${tag})`}
      <strong>{`${value}V`}</strong>
    {:else}unknown{/if}
  </div>
  <div>3.4V=0%, 4.1V=100%</div>
</Card>
