import { firestore } from '../firebase'

export const createUserProfile = async (user, additionalData) => {
  if (!user) return

  const { uid, email } = user
  const userRef = firestore.doc(`users/${uid}`)
  const snapshot = await userRef.get()

  if (!snapshot.exists) {
    try {
      await userRef.set({
        email,
        ...additionalData
      })
    } catch (error) {
      console.error({ error }) // we may want to do something bettern with the error
    }
  }

  return getUserProfile(uid)
}

export const getUserProfile = async (uid) => {
  if (!uid) return null

  try {
    const userData = firestore.collection('users').doc(uid).get()

    return userData.then((doc) => {
      const data = doc.exists ? doc.data() : null
      return {
        uid,
        ...data
      }
    })
  } catch (error) {
    console.error({ error }) // we may want to do something bettern with the error
  }
}
