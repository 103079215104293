<script>
  import { getContext } from 'svelte'
  import { DateTime } from 'luxon'

  import DateSelector from '../../common/components/DateSelector.svelte'
  import Button from '../../common/components/Button.svelte'
  import { exportData } from '../../store/exportData'
  import { loading } from '../../store/loading'
  import { downloadCSV } from '../../common/csv'
  import { getToday } from '../Utils/DateHelper'
  import { functions } from '../../firebase'
  import { RowIDSeperator } from './Constants'

  export let selectedDate

  const MAX_EXPORT_DAYS = 180

  const { close } = getContext('simple-modal')

  let options = ['Last 30 days', 'Last 60 days', 'Custom Range']
  let selected = options[0]
  let fields = { explicitStart: null, explicitEnd: null }
  let errors = { explicitStart: '', explicitEnd: '' }
  $: customRangeSelected = selected === options[2]

  const getTimeRangeForDays = (daysAgo) => ({
    start: DateTime.local().toFormat('yyyy-MM-dd'),
    end: DateTime.local().minus({ days: daysAgo }).toFormat('yyyy-MM-dd')
  })

  const getDateRange = (selected, { explicitStart, explicitEnd }) => {
    if (selected === options[0]) {
      return getTimeRangeForDays(30)
    } else if (selected === options[1]) {
      return getTimeRangeForDays(60)
    } else {
      return { start: explicitStart, end: explicitEnd }
    }
  }

  $: ({ start, end } = getDateRange(selected, fields))

  const handleSubmit = async () => {
    if (customRangeSelected) {
      if (!fields.explicitStart) {
        errors.explicitStart = 'Start date is required'
        return
      } else {
        errors.explicitStart = ''
      }

      if (!fields.explicitEnd) {
        errors.explicitEnd = 'End date is required'
        return
      } else {
        errors.explicitEnd = ''
      }

      if (DateTime.fromISO(fields.explicitEnd) < DateTime.fromISO(fields.explicitStart)) {
        errors.explicitEnd = 'End date is before start date'
        return
      } else {
        errors.explicitEnd = ''
      }

      if (
        DateTime.fromISO(fields.explicitEnd).diff(DateTime.fromISO(fields.explicitStart), 'days').days > MAX_EXPORT_DAYS
      ) {
        errors.explicitEnd = `Maxiumum period is ${MAX_EXPORT_DAYS} days`
        return
      } else {
        errors.explicitEnd = ''
      }
    }

    await getCSV()
    close()
  }

  const dateRange = () => ({
    'Last 30 days': {
      start: selectedDate.minus({ days: 30 }).toFormat('yyyy-MM-dd'),
      end: selectedDate.plus({ days: 1 }).toFormat('yyyy-MM-dd')
    },
    'Last 60 days': {
      start: selectedDate.minus({ days: 60 }).toFormat('yyyy-MM-dd'),
      end: selectedDate.plus({ days: 1 }).toFormat('yyyy-MM-dd')
    }
  })

  const getCSV = async () => {
    const requests = []
    const customerRows = $exportData.exportRows
    if (customerRows.length == 0) return false

    try {
      loading.set(true)
      customerRows.forEach(({ id, tz_offset }) => {
        const customerId = id.split(RowIDSeperator)[0]
        requests.push(
          functions.httpsCallable('exportSensorDataCSV')({
            customerId,
            tz_offset,
            start: dateRange()[selected] ? dateRange()[selected].start : fields.explicitStart,
            end: dateRange()[selected] ? dateRange()[selected].end : fields.explicitEnd
          })
        )
      })
      const filesData = await Promise.all(requests)

      filesData.forEach((fileData) => {
        downloadCSV(fileData.data.csvData, `${fileData.data.customerId}_${getToday()}.csv`)
      })
    } finally {
      loading.set(false)
    }

    return true
  }
</script>

<style>
  .container {
    padding: 1rem 2rem;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
  }

  .radio-label {
    color: #636363;
  }
</style>

<form on:submit|preventDefault={handleSubmit} class="container">
  <h2>Export CSV</h2>
  <label for="date-range">Select a date range</label>

  <div class="row">
    {#each options as value}
      <span>
        <input type="radio" name="date-range" {value} bind:group={selected} />
        <span class="radio-label">{value}</span>
      </span>
    {/each}
  </div>

  <div class="row">
    <DateSelector
      bind:value={fields.explicitStart}
      label="From:"
      disabled={!customRangeSelected}
      error={errors.explicitStart} />

    <DateSelector
      bind:value={fields.explicitEnd}
      label="To:"
      disabled={!customRangeSelected}
      error={errors.explicitEnd} />
  </div>

  <div class="row">
    <Button type="submit">Export</Button>
    <a href on:click|preventDefault={close}>Cancel</a>
  </div>
</form>
