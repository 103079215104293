import { writable } from 'svelte/store'

export const STORE_NAME = 'deviceDiagnosticsDashboard'

const initialState = {
  token: null
}

const createDeviceDashboardStore = () => {
  const { subscribe } = writable(JSON.parse(localStorage.getItem(STORE_NAME)) || initialState)

  return {
    subscribe,
    updateToken: (token) => {
      const store = JSON.stringify({ token })
      localStorage.setItem(STORE_NAME, store)
    },
    logout: () => localStorage.setItem(STORE_NAME, JSON.stringify(initialState))
  }
}

export const deviceDiagnosticsDashboard = createDeviceDashboardStore()
