<script>
  export let label
  export let disabled
  export let value = null
  export let error
</script>

<style>
  input[type='date'] {
    color: grey;
    border: none;
    padding: 0.5rem;
    outline: none;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }

  .wrapper {
    display: flex;
    align-items: center;
    border: 2px solid lightgrey;
    border-radius: 5px;
    padding-left: 1rem;
  }

  .disabled {
    background-color: #fafafa;
  }

  .error {
    border-color: #ff0000 !important;
  }

  .error-message {
    color: #ff0000;
    font-size: 10px;
  }
</style>

<div class="container">
  <div class:disabled class={!disabled && error ? 'wrapper error' : 'wrapper'}>
    <label for="date">{label}</label>
    <input bind:value type="date" name="date" data-testid={label} {disabled} />
  </div>

  <p class="error-message">
    {#if !disabled}{error}{/if}
  </p>
</div>
