<script>
  import Card from '../Card.svelte'
  export let deviceData = []

  const normaliseMeasurement = (measurement) => {
    return Number(measurement) > 1024 ? 1024 : measurement
  }

  const getLEDCount = (value) => (normaliseMeasurement(value) / 256).toFixed(1)

  const getLEDStatusColor = (value) => {
    const leds = getLEDCount(value)

    if (4 >= leds && leds >= 3) {
      return 'green'
    } else if (3 > leds && leds > 1) {
      return 'orange'
    } else {
      return 'red'
    }
  }

  $: imag = deviceData.find((data) => data.tag === 'imag') || {}
  $: ivol = deviceData.find((data) => data.tag === 'ivol') || {}
  $: irsi = deviceData.find((data) => data.tag === 'irsi') || {}
</script>

<Card timestamp={irsi.utc2 || irsi.utc1} title="Field Measurements">
  <div>
    Install time Wifi RSSI value:
    {#if irsi.value}
      <strong>{irsi.value + 'dB'}</strong>
    {:else}unknown{/if}
  </div>
  <div>
    Magnetic field measurement:
    {#if imag.value}
      <strong>{imag.value}</strong>
      <span style={`color: ${getLEDStatusColor(imag.value)}`}>{`(${getLEDCount(imag.value)} LEDs)`}</span>
    {:else}unknown{/if}
  </div>
  <div>
    Electric field measurement:
    {#if ivol.value}
      <strong>{ivol.value}</strong>
      <span style={`color: ${getLEDStatusColor(ivol.value)}`}>{`(${getLEDCount(ivol.value)} LEDs)`}</span>
    {:else}unknown{/if}
  </div>
</Card>
