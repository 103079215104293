<script>
  import { isLocalhost } from './utils/isLocalhost'

  export let useLocalData
</script>

<style>
  .white {
    color: white;
  }

  .data-source-selection {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-right: 0.5rem;
  }

  .data-source-selection > label {
    margin: 0;
    font-weight: normal;
  }
</style>

{#if isLocalhost}
  <div class="data-source-selection">
    <span>
      <strong>Data source:</strong>
    </span>
    <label class="white">
      <input type="radio" bind:group={useLocalData} value={true} />
      Local data
    </label>

    <label class="white">
      <input type="radio" bind:group={useLocalData} value={false} />
      Admin console
    </label>
  </div>
{/if}
