<script>
  import Card from '../Card.svelte'
  export let deviceData = []

  $: rssiData = deviceData.find((data) => data.tag === 'rssi') || {}
  $: value = rssiData.value
  $: tag = rssiData.tag
  $: utc1 = rssiData.utc1
  $: utc2 = rssiData.utc2
  $: alarm = !(value < -40 && value > -80)
</script>

<Card timestamp={utc2 || utc1} title="WiFi">
  <div class="title">
    <span style={`color: ${alarm ? 'orange' : 'green'}`}>{`${alarm ? '?' : 'OK'}`}</span>
  </div>
  <div>
    {#if value}
      {`Value (${tag}):`}
      <strong>{`${value}dB`}</strong>
    {:else}unknown{/if}
  </div>
  <div>-40 is excellent, -90 is poor</div>
</Card>
