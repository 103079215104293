<script>
  import { DateTime } from 'luxon'
  import { onMount } from 'svelte'
  import { filters } from '../../store/filters'

  let selectedDate = null,
    fromDate = null,
    effect = null

  const formatString = 'ccc, dd MMMM'

  $: fromDate = selectedDate ? selectedDate.minus({ days: $filters.timeRangeFilter }) : DateTime.local()

  const removeAnimation = () => {
    setTimeout(() => {
      effect = null
    }, 200)
  }
  const moveBack = () => {
    selectedDate = selectedDate.minus({ days: $filters.timeRangeFilter })
    fromDate = selectedDate.minus({ days: $filters.timeRangeFilter })
    filters.updateDateFilter(selectedDate)
    effect = 'animation__slide_left'
    removeAnimation()
  }
  const moveForward = () => {
    selectedDate = selectedDate.plus({ days: $filters.timeRangeFilter })
    fromDate = selectedDate.plus({ days: $filters.timeRangeFilter })
    filters.updateDateFilter(selectedDate)
    effect = 'animation__slide_right'
    removeAnimation()
  }

  $: endDate = () => (fromDate && $filters.timeRangeFilter != 1 ? ` - ${fromDate.toFormat(formatString)}` : '')

  onMount(() => {
    selectedDate = $filters.dateFilter ? $filters.dateFilter : DateTime.local().minus({ days: 1 })
    fromDate = selectedDate.minus({ days: $filters.timeRangeFilter })
    filters.updateDateFilter(selectedDate)
  })
</script>

<style>
  .svelte__custom_date_wrapper {
    width: 24rem;
    height: fit-content;
  }
</style>

<div
  class="svelte__custom_date_wrapper typography__white layout__align_right toolbar__date_filter
  layout__flex_space_between">
  <div class="utils__arrow_left utils__pointer" on:click={moveBack} />
  <span class={effect}>{selectedDate && selectedDate.toFormat(formatString)} {endDate()}</span>
  <div class="utils__arrow_right utils__pointer" on:click={moveForward} />
</div>
