<script>
  export let onSubmit

  let deviceId = ''

  const handleSearch = async () => {
    await onSubmit(deviceId)
  }
</script>

<style>
  .search-input {
    width: 14rem;
    height: 2.5rem;
  }

  .search-button {
    background-color: var(--primary_blue_secondary);
    height: 2.5rem;
  }

  .search-form {
    display: flex;
    align-items: center;
  }
</style>

<form class="search-form" on:submit|preventDefault={handleSearch}>
  <input class="search-input" type="text" placeholder="device id" bind:value={deviceId} />
  <button class="search-button">Search</button>
</form>
