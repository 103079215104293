<script>
  import { results } from '../../store/results'
  import { sortBy, prop } from 'ramda'

  const InfoList = [
    { title: 'Customers', value: 0 },
    { title: 'Consumption (kWh)', value: 0 },
    { title: 'Generation (kWh)', value: 0 },
    { title: 'Export to grid (kWh)', value: 0 },
    { title: 'Net usage (kWh)', value: 0 }
  ]

  const findAttributeTotal = (attrName, listResult) => {
    let aggregate = 0

    if (listResult && listResult.length === 0) {
      return aggregate
    }

    listResult.forEach((item) => {
      aggregate += isNaN(item[attrName]) ? 0 : item[attrName]
    })

    return aggregate
  }

  const getTotalIdenticalCustomer = (sortedResult) => {
    let currentCustomerId = '',
      totalIdenticalCustomer = 0
    sortedResult.forEach((item) => {
      if (item.customerId !== currentCustomerId) totalIdenticalCustomer += 1
      currentCustomerId = item.customerId
    })

    return totalIdenticalCustomer
  }

  $: {
    if ($results.search) {
      const totalIdenticalCustomer = getTotalIdenticalCustomer(sortBy(prop('customerId'), $results.search))
      InfoList[0].value = Math.round(totalIdenticalCustomer)

      const householdTotal = findAttributeTotal('household', $results.search)
      InfoList[1].value = Math.round(householdTotal)

      const generationTotal = findAttributeTotal('totalsolar', $results.search)
      InfoList[2].value = Math.round(generationTotal)

      const togridTotal = findAttributeTotal('togrid', $results.search)
      InfoList[3].value = Math.round(togridTotal)

      InfoList[4].value = Math.round(householdTotal - generationTotal)
    }
  }
</script>

<style>
  .svelte__aggregate {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid black;
    padding: 0 1rem;
  }

  .svelte__aggregate:last-child {
    border-right: 0;
  }
</style>

<div class="layout__flex layout__flex_container_center svelte__wrapper animation__slide_top">
  {#each InfoList as info}
    <div class="layout__flex_item_vertical_center svelte__aggregate">
      <div class="typography__header_1 typography__heavy typography__primary_blue">{info.value}</div>
      <div class="typography__header_4 typography__medium">{info.title}</div>
    </div>
  {/each}
</div>
