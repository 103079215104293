export const CustomerTableColumns = [
  {
    key: 'check',
    title: '',
    value: ({ check }) => check
  },
  {
    key: 'customerId',
    title: 'Customer Number',
    value: ({ customerId }) => customerId,
    sortable: true
  },
  {
    key: 'region',
    title: 'Region',
    value: ({ region }) => region,
    sortable: true
  },
  {
    key: 'household',
    title: 'Total Consumption (kWh)',
    value: ({ household }) => household,
    sortable: true
  },
  {
    key: 'fromgrid',
    title: 'Imported from grid (kWh)',
    value: ({ fromgrid }) => fromgrid,
    sortable: true
  },
  {
    key: 'fromsolar',
    title: 'Solar Consumption (kWh)',
    value: ({ fromsolar }) => fromsolar,
    sortable: true
  },
  {
    key: 'togrid',
    title: 'Exported to grid (kWh)',
    value: ({ togrid }) => togrid,
    sortable: true
  },
  {
    key: 'totalsolar',
    title: 'Total Generation (kWh)',
    value: ({ totalsolar }) => totalsolar,
    sortable: true
  },
  {
    key: 'netcost',
    title: 'Net Cost/Gain ($)',
    value: ({ netcost }) => netcost,
    sortable: true
  },
  {
    key: 'starttime',
    title: 'Date',
    value: ({ starttime }) => starttime,
    sortable: true
  }
]

export const RowIDSeperator = '|'
