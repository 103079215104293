<script>
  import Map from './Map.svelte'
  import LoadingModal from '/components/App/LoadingModal.svelte'
  import { googleMaps } from '../../store/maps'
  import ToolBar from '../Table/ToolBar.svelte'

  let ready = false
  googleMaps.subscribe(() => {
    ready = true
  })
</script>

<style>
  .map-container {
    padding-top: 12rem;
    width: 100vw;
    flex: 1;
  }
</style>

<ToolBar />
<LoadingModal busy={!ready} />
<div class="map-container layout__flex">
  {#if ready}
    <Map />
  {/if}
</div>
