<script>
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  const toggleLeftDrawer = () => dispatch('toggleLeftDrawer')

  export let isOpen
</script>

<style>
  button {
    margin: 5px 0 5px 5px;
    height: 40px;
    width: 40px;
    padding: 0 15px 0 15px;
    border-radius: 50%;
    border-bottom: none;
  }
  button:hover,
  button:not(:disabled):hover {
    background-color: rgb(68, 196, 0);
    -webkit-transition: background-color 0.2s ease-in;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    border-bottom: none;
  }
  .material-icons {
    position: relative;
    top: 1px;
    left: -7px;
    color: #fff;
  }
  .open {
    background-color: rgb(68, 196, 0);
  }
</style>

<button on:click={toggleLeftDrawer} class={isOpen ? 'open' : ''}>
  <i class="material-icons">menu</i>
</button>
