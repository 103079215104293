<script>
  import { onMount, onDestroy } from 'svelte'
  import { Router, Route } from 'svelte-navigator'
  import Login from '../Login/Login.svelte'
  import ResetPassword from '../ResetPassword/ResetPassword.svelte'
  import App from './App.svelte'
  import { auth, googleAPIKey } from '../../firebase'
  import { createUserProfile } from '../../common/profileManagement'
  import { userStore } from '../../store/user'
  import { loading } from '../../store/loading'
  import LoadingModal from './LoadingModal.svelte'

  let RootApp = null
  let unsubscribeFromAuth
  let googleAPI = googleAPIKey

  let busy = false

  const unsubscribe = loading.subscribe((value) => {
    busy = value
  })

  // wrapped auth in lifcecle hooks to obtain and use unsubscribe handle
  onMount(() => {
    unsubscribeFromAuth = auth.onAuthStateChanged(async (authUser) => {
      // 1 - we can then updated role in the database to something like 'admin'
      // 2 - this function is returns a user profile document.  I suggest we can store in Svele's state and make it available to the app,
      // that way we don't have to double-handle this event in auth.js
      let user = null
      if (authUser) {
        user = await createUserProfile(authUser, { role: 'provider' }) // later on we are going to store solarplatform access token aginst this profile
      }
      userStore.set(user)
      RootApp = authUser ? App : Login
    })
  })

  onDestroy(unsubscribeFromAuth)
  onDestroy(unsubscribe)
</script>

<LoadingModal {busy} />

<svelte:head>
  <script defer async src={`https://maps.googleapis.com/maps/api/js?key=${googleAPI}&callback=initMap`}>

  </script>
</svelte:head>

<Router primary={false}>
  <Route path="/" component={RootApp} />
  <Route path="reset-password" component={ResetPassword} />
  <Route component={RootApp} />
</Router>
