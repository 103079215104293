export default {
  ha3r: { utc1: 1595289637, utc2: 1595289638, value: '-23784' },
  upnn: { utc1: 1593154785, utc2: 1593154785, value: '1' },
  tsun: { utc1: 1595289639, utc2: 1595289639, value: '362' },
  pham: { utc1: 1595289565, utc2: 1595289566, value: '0.167' },
  upts: { utc1: 1595287522, utc2: 1595287522, value: '184644' },
  pbpd: { utc1: 1595289637, utc2: 1595289637, value: '6.5918' },
  upsv: { utc1: 1595287522, utc2: 1595287522, value: '0' },
  fa: { utc1: 1582686270, utc2: null, value: '1' },
  pbvo: { utc1: 1595289637, utc2: 1595289638, value: '246.626' },
  pbam: { utc1: 1595289637, utc2: 1595289638, value: '0.323' },
  fw: { utc1: 1582686270, utc2: null, value: '1' },
  ha5i: { utc1: 1595289637, utc2: 1595289638, value: '-3099696' },
  hwrv: { utc1: 1595216188, utc2: 1595216188, value: '0' },
  saco: { utc1: 1595289540, utc2: 1595289541, value: '-9804' },
  tsup: { utc1: 1595289639, utc2: 1595289639, value: '17379' },
  init: { utc1: 1594944482, utc2: 1594944482, value: '1' },
  cali: { utc1: 1595216188, utc2: 1595216188, value: '0' },
  upln: { utc1: 1594944472, utc2: 1594944472, value: '451' },
  fwh: { utc1: 1582686270, utc2: null, value: '1' },
  corr: { utc1: 1584515400, utc2: 1584515430, value: '1.002859' },
  tstp: { utc1: 1595289639, utc2: 1595289639, value: '2124' },
  ptmp: { utc1: 1595289637, utc2: 1595289638, value: '28.5' },
  bler: { utc1: 1594960099, utc2: 1594960099, value: '5' },
  bles: { utc1: 1594960101, utc2: 1594960101, value: '176035' },
  phwa: { utc1: 1595289565, utc2: 1595289566, value: '6.194' },
  pbhz: { utc1: 1584515400, utc2: 1584515430, value: '49.972697' },
  tsts: { utc1: 1595289639, utc2: 1595289639, value: '329539' },
  uppt: { utc1: 1595287522, utc2: 1595287522, value: '0' },
  vpa3: { utc1: 1579751214, utc2: 1579751220, value: '1.6711' },
  phvo: { utc1: 1595289565, utc2: 1595289566, value: '244.855' },
  ha3i: { utc1: 1595289637, utc2: 1595289638, value: '912768' },
  phws: { utc1: 1595289638, utc2: 1595289638, value: '1000026959' },
  'fwtype/plug/wroom32/rev1': {
    utc1: 1594944483,
    utc2: 1594944483,
    value: '3241'
  },
  uptm: { utc1: 1595289637, utc2: 1595289637, value: '505570' },
  pbts: { utc1: 1595289637, utc2: 1595289637, value: '329536' },
  rssi: { utc1: 1595289639, utc2: 1595289639, value: '-49' },
  uper: { utc1: 1594944472, utc2: 1594944472, value: '258' },
  upbu: { utc1: 1594944710, utc2: 1594944710, value: '7' },
  pbwa: { utc1: 1595289637, utc2: 1595289638, value: '1.947' },
  fcnt: { utc1: 1595289639, utc2: 1595289639, value: '620' },
  upis: { utc1: 1595287522, utc2: 1595287522, value: '3241' },
  vpa7: { utc1: 1579751214, utc2: 1579751220, value: '110.8006' },
  ha7i: { utc1: 1595289637, utc2: 1595289638, value: '-545448' },
  ha7r: { utc1: 1595289637, utc2: 1595289638, value: '6540552' },
  loip: { utc1: 1594944463, utc2: 1594944463, value: '173058240' },
  ha5r: { utc1: 1595289637, utc2: 1595289638, value: '-5119920' },
  blad: { utc1: 1595287702, utc2: 1595287702, value: '1888280419' },
  fv: { utc1: 1582686270, utc2: null, value: '1' },
  upcf: { utc1: 1592876611, utc2: 1592876611, value: '3241' },
  pbws: { utc1: 1595289637, utc2: 1595289637, value: '659946' },
  vpa5: { utc1: 1579751214, utc2: 1579751220, value: '-125.2996' },
  pbc: { utc1: 1595289637, utc2: 1595289638, value: '0.008245,-0.003224' },
  pbtp: { utc1: 1595289637, utc2: 1595289637, value: '18000' },
  tsus: { utc1: 1595289639, utc2: 1595289639, value: '1595289639' },
  cprr: { value: 99991010 },
  upau: { value: -1, utc1: 1595289639 }
}
