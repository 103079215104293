<script>
  export let devicesInArea
  export let numberOfAllDevices
  export let deviceWithNoGeoLocation
  let devicesOutOfArea

  $: {
    if (devicesInArea == numberOfAllDevices) {
      devicesOutOfArea = 0
    } else {
      devicesOutOfArea = numberOfAllDevices - devicesInArea
    }
  }
</script>

<style>
  #displayedDeviceInfo {
    display: none;
    border: 0.5px solid #c2c2c2;
    border-radius: 10px;
    background-color: white;
    width: 400px;
    height: 50px;
    margin-right: 30px;
    margin-bottom: 30px;
    font-size: 12px;
    padding: 5px 10px 5px 10px;
  }

  #deviceInfoText {
    display: flex;
    justify-content: space-around;
  }
</style>

<div id="displayedDeviceInfo">
  <p id="deviceInfoText">
    <b>Showing:</b>
    <span>{devicesInArea} in area</span>
    |
    <span>{devicesOutOfArea} out of area</span>
    |
    <span>{deviceWithNoGeoLocation} No geo-location</span>
  </p>
</div>
