export const firebaseConfig = {
  apiKey: 'AIzaSyDulfJ6UfNzdvzw3J9UTPn9EhTsVSGjA38',
  authDomain: 'dius-powersensor-portal-prod.firebaseapp.com',
  databaseURL: 'https://dius-powersensor-portal-prod.firebaseio.com',
  projectId: 'dius-powersensor-portal-prod',
  storageBucket: 'dius-powersensor-portal-prod.appspot.com',
  messagingSenderId: '646252457083',
  appId: '1:646252457083:web:49ff939fa84104041b86de',
  measurementId: 'G-ZFWN2ZLS1Z'
}
