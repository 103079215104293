export default {
  ae01: { utc1: 1566705241, utc2: 1566705540, value: '-39' },
  tssr: { utc1: 1566704856, utc2: 1566704856, value: '149254' },
  klrv: { utc1: 1566555672, utc2: 1566555673, value: '3' },
  tssu: { utc1: 1566704856, utc2: 1566704856, value: '1566704856' },
  bupu: { utc1: 1566705540, utc2: 1566705540, value: '25062187' },
  batt: { utc1: 1566704851, utc2: 1566705540, value: '3.007715' },
  ptji: { utc1: 1566705509, utc2: 1566705539, value: '0.029104' },
  bues: { utc1: 1566705540, utc2: 1566705540, value: '283325' },
  tscu: { utc1: 1566704856, utc2: 1566704856, value: '2670' },
  am02: { utc1: 1566705241, utc2: 1566705540, value: '-7561' },
  upis: { utc1: 1566704864, utc2: 1566704865, value: '620' },
  m00: { utc1: 1566705538, utc2: 1566705539, value: '-37.13,-25.96' },
  m02: { utc1: 1566705538, utc2: 1566705539, value: '-41.99,-34.4' },
  irsi: { utc1: 1566555663, utc2: 1566555664, value: '-73' },
  klpn: { utc1: 1566555672, utc2: 1566555673, value: '5' },
  tsdr: { utc1: 1566704856, utc2: 1566704856, value: '-1053' },
  tsch: { utc1: 1566704856, utc2: 1566704856, value: '-16.928' },
  ptms: { utc1: 1566705509, utc2: 1566705539, value: '20.0015' },
  samc: { utc1: 1566705538, utc2: 1566705539, value: '7044331' },
  tsr2: { utc1: 1566705544, utc2: 1566705545, value: '42.228' },
  am04: { utc1: 1566705241, utc2: 1566705540, value: '2366' },
  ktmp: { utc1: 1566705509, utc2: 1566705539, value: '14.555' },
  m03: { utc1: 1566705538, utc2: 1566705539, value: '-68.57,-49.71' },
  m04: { utc1: 1566705538, utc2: 1566705539, value: '79.22,66.73' },
  ivol: { utc1: 1566555665, utc2: 1566555665, value: '297' },
  woui: { utc1: 1566705543, utc2: 1566705544, value: '8179' },
  buca: { utc1: 1566705540, utc2: 1566705540, value: '1452000' },
  rntm: { utc1: 1566704864, utc2: 1566704865, value: '14.81427' },
  budg: { utc1: 1566705540, utc2: 1566705540, value: '25624169' },
  am00: { utc1: 1566705241, utc2: 1566705540, value: '-8705' },
  tsof: { utc1: 1566704856, utc2: 1566704856, value: '40459' },
  tspr: { utc1: 1566705544, utc2: 1566705545, value: '22.995' },
  e00: { utc1: 1566705538, utc2: 1566705539, value: '5266,-201' },
  m01: { utc1: 1566705538, utc2: 1566705539, value: '55.97,47.16' },
  chnl: { utc1: 1566705544, utc2: 1566705545, value: '4' },
  tsca: { utc1: 1566704856, utc2: 1566704856, value: '-9.28' },
  nwkc: { utc1: 1566705543, utc2: 1566705544, value: '3.632129' },
  klra: { utc1: 1566555672, utc2: 1566555673, value: '8' },
  imag: { utc1: 1566555665, utc2: 1566555665, value: '1144' },
  tscr: { utc1: 1566704856, utc2: 1566704856, value: '27986' },
  blea: { utc1: 1566705538, utc2: 1566705539, value: '0' },
  phym: { utc1: 1566705543, utc2: 1566705544, value: '3' },
  m05: { utc1: 1566705538, utc2: 1566705539, value: '77.62,85.01' },
  e01: { utc1: 1566705538, utc2: 1566705539, value: '729,4' },
  rssi: { utc1: 1566705544, utc2: 1566705545, value: '-80' },
  bura: { utc1: 1566705540, utc2: 1566705540, value: '12000' },
  budi: { utc1: 1566705540, utc2: 1566705540, value: '1615800' },
  am03: { utc1: 1566705241, utc2: 1566705540, value: '-1065' },
  upsl: { utc1: 1566704864, utc2: 1566704865, value: '0' },
  upld: { utc1: 1566704864, utc2: 1566704865, value: '10.339428' },
  ae00: { utc1: 1566705241, utc2: 1566705540, value: '3' },
  dnss: { utc1: 1566705544, utc2: 1566705545, value: '32745664' },
  am05: { utc1: 1566705241, utc2: 1566705540, value: '-13757' },
  upct: { utc1: 1566704864, utc2: 1566704865, value: '12271' },
  tsr1: { utc1: 1566705544, utc2: 1566705545, value: '99.221' },
  kvcc: { utc1: 1566704851, utc2: 1566705540, value: '3.010174' },
  am01: { utc1: 1566705241, utc2: 1566705540, value: '2983' },
  buof: { utc1: 1566705540, utc2: 1566705540, value: '12400' },
  klmd: { utc1: 1566555672, utc2: 1566555673, value: '113' },
  phyf: { utc1: 1566705541, utc2: 1566705542, value: '3' },
  busa: { utc1: 1566705540, utc2: 1566705540, value: '252000' },
  nwku: { utc1: 1566705544, utc2: 1566705545, value: '4.191802' },
  'fwtype/sensor/rev6/hw0/kl64': {
    utc1: 1567474565,
    utc2: 1567474565,
    value: '620'
  },
  busp: { utc1: 1566555666, utc2: 1566555666, value: '99259326' },
  bups: { utc1: 1566705540, utc2: 1566705540, value: '5460' }
}
