<script>
  import { exportData } from '../../store/exportData'

  export let allRows
  const checkboxSelector = ".customerDataTable input[type='checkbox']"
  const selectAll = () => {
    const checkboxes = document.querySelectorAll(checkboxSelector)

    if (checkboxes && checkboxes.length > 0) {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true
      })

      exportData.upsertAllRows(allRows)
    }
  }

  const deselectAll = () => {
    const checkboxes = document.querySelectorAll(checkboxSelector)

    if (checkboxes && checkboxes.length > 0) {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false
      })

      exportData.clear()
    }
  }

  const handleChange = (e) => {
    if (e.target.checked) {
      selectAll()
    } else {
      deselectAll()
    }
  }
</script>

<style>
  .margin_adjust {
    margin-right: 1rem;
  }

  .container_margin_adjust {
    margin-left: -1rem;
  }
</style>

<div class="layout__flex layout__flex_container_vertical_center container_margin_adjust">
  <div class="margin_adjust html__checkbox">
    <label class="html__checkbox-container">
      <input type="checkbox" on:change={handleChange} disabled={allRows && allRows.length == 0} />
      <span class="html__checkmark" />
    </label>
  </div>
  <div class="utils__uppercase typography__less_contract_font">Select All</div>
</div>
