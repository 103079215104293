import { DateTime } from 'luxon'
import { pathOr, reduce, compose, values, groupBy, map, addIndex, flatten } from 'ramda'
import { get } from 'svelte/store'

import { RowIDSeperator } from '../components/Table/Constants'
import { results } from '../store/results'
import { loading } from '../store/loading'
import { googleMaps } from '../store/maps'
import { geoCache } from '../store/geoCache'
import { firestore } from '../firebase'

const recordDateFormat = 'dd MMM yyyy'
const recordDateFormatShort = 'dd MMM'
const recordYear = 'yyyy'
const mapIndexed = addIndex(map)
const extractDocs = (docs) => map((doc) => doc.data(), docs)
const formatNumberValue = (value) => Math.round(value * 100) / 100

const formatDateColumn = (starttime, timeRange) => {
  const fromDate = DateTime.fromSeconds(starttime.seconds)
  const toDate = fromDate.plus({ day: timeRange - 1 })
  const dateFormat =
    fromDate.toFormat(recordYear) === DateTime.local().toFormat(recordYear) ? recordDateFormatShort : recordDateFormat

  return toDate.toFormat('dd') === fromDate.toFormat('dd')
    ? toDate.toFormat(dateFormat)
    : `${fromDate.toFormat(dateFormat)} - ${toDate.toFormat(dateFormat)}`
}

const fetchLocationData = ({ location }) => {
  return new Promise((resolve, reject) => {
    try {
      if (!location || !location.Pc || !location.Vc) return resolve('N/A')
      const cachedValue = get(geoCache)[`${location.Pc},${location.Vc}`]
      if (cachedValue) return resolve(cachedValue)

      googleMaps.subscribe((google) => {
        const geocoder = new google.maps.Geocoder()
        geocoder.geocode({ location: { lat: location.Pc, lng: location.Vc } }, (results) => {
          if (results && results.length > 0) {
            const address = results[0].formatted_address.split(',')[1].trim()
            geoCache.update((current) => {
              current[`${location.Pc},${location.Vc}`] = address
              return current
            })
            resolve(address)
          } else {
            resolve('N/A')
          }
        })
      })
    } catch (_e) {
      reject('N/A')
    }
  })
}

const formatRow = async (sensorData, index, timeRange = 0) => {
  const rowId = `${pathOr('none', ['customerId'], sensorData)}${RowIDSeperator}${index}`
  const location = await fetchLocationData(sensorData)
  return {
    id: rowId,
    check: `<label class="html__checkbox-container">
              <input type="checkbox" id="${rowId}" disabled />
              <span class="html__checkmark"></span>
      </label>`,
    customerId: sensorData.customerId,
    region: location,
    household: formatNumberValue(sensorData.household) || null,
    fromgrid: formatNumberValue(sensorData.fromgrid) || null,
    fromsolar: formatNumberValue(sensorData.fromsolar) || null,
    togrid: formatNumberValue(sensorData.togrid) || null,
    totalsolar: formatNumberValue(sensorData.totalsolar) || null,
    netcost: formatNumberValue(sensorData.netcost) || null,
    starttime: formatDateColumn(sensorData.starttime, timeRange),
    tz_offset: sensorData.tz_offset
  }
}

const normaliseSensorData = (sensorDataRecords) =>
  map(
    (sensorDataRecord) => ({
      customerId: pathOr('none', ['customerId'], sensorDataRecord),
      location: pathOr('none', ['location'], sensorDataRecord),
      household: pathOr(0, ['household'], sensorDataRecord),
      fromgrid: pathOr(0, ['fromgrid'], sensorDataRecord),
      fromsolar: pathOr(0, ['fromsolar'], sensorDataRecord),
      togrid: pathOr(0, ['togrid'], sensorDataRecord),
      totalsolar: pathOr(0, ['totalsolar'], sensorDataRecord),
      netcost: pathOr(0, ['netcost'], sensorDataRecord),
      starttime: pathOr('none', ['starttime'], sensorDataRecord),
      tz_offset: sensorDataRecord.tz_offset
    }),
    sensorDataRecords
  )

const groupedCustomerData = (docs) =>
  compose(
    values,
    groupBy((record) => record.customerId),
    normaliseSensorData
  )(extractDocs(docs))

const aggregate = (customerRecords) =>
  reduce(
    (acc, cur) => {
      let result = acc ? acc : { ...cur, household: 0, fromgrid: 0, fromsolar: 0, togrid: 0, totalsolar: 0, netcost: 0 }
      result.household += cur.household
      result.fromgrid += cur.fromgrid
      result.fromsolar += cur.fromsolar
      result.togrid += cur.togrid
      result.totalsolar += cur.totalsolar
      result.netcost += cur.netcost

      return result
    },
    null,
    customerRecords
  )

const getQuery = (customer, timeRange, selectedDate, period = 'daily') => {
  let query = firestore.collection(`sensordata_${period}`).orderBy('starttime')

  const endtime = selectedDate
    ? DateTime.fromJSDate(selectedDate.toJSDate()).setZone(customer.timezone).endOf('day').toJSDate()
    : null

  const starttime = selectedDate
    ? DateTime.fromJSDate(selectedDate.toJSDate())
        .setZone(customer.timezone)
        .minus({ days: timeRange - 1 })
        .startOf('day')
        .toJSDate()
    : null

  query = query.where('customerId', '==', customer.customerId)
  if (starttime) query = query.where('starttime', '>=', starttime)
  if (endtime) query = query.where('starttime', '<', endtime)

  return query
}

export const reloadData = async (customers, timeRange, selectedDate) => {
  loading.set(true)
  let customerData = await Promise.all(
    customers.map(async (customer) => {
      const snapshot = await getQuery(customer, timeRange, selectedDate).get()
      return mapIndexed(formatRow, map(aggregate, groupedCustomerData(snapshot.docs)), timeRange)
    })
  )

  results.updateSearchResult(await Promise.all(flatten(customerData)))
  loading.set(false)
}

export const chartData = async (customers, timeRange, selectedDate) =>
  await Promise.all(
    customers.map(async (customer) => {
      const snapshot = await getQuery(customer, timeRange, selectedDate, 'halfhourly').get()
      return { customer, data: snapshot.docs.map((doc) => doc.data()) }
    })
  )
