<script>
  import Card from '../Card.svelte'
  export let deviceData = []

  $: upis = deviceData.find((data) => data.tag === 'upis') || {}
</script>

<Card timestamp={upis.utc2 || upis.utc1} title="Firmware">
  <div>{upis.value ? `v${upis.value} (${upis.tag})` : 'unknown'}</div>
</Card>
