import { writable } from 'svelte/store'

const createFilterStore = () => {
  const { subscribe, update } = writable({
    timeRangeFilter: 1,
    dateFilter: null,
    customerIdFilter: []
  })

  return {
    subscribe,
    updateTimeRangeFilter: (newTimeRange) => update((filters) => ({ ...filters, timeRangeFilter: newTimeRange })),
    updateCustomerIdFilter: (customerIds) => update((filters) => ({ ...filters, customerIdFilter: customerIds })),
    resetCustomerIdFilter: () => update((filters) => ({ ...filters, customerIdFilter: [] })),
    updateDateFilter: (targetDate) => update((filters) => ({ ...filters, dateFilter: targetDate }))
  }
}

export const filters = createFilterStore()
