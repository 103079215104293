<script>
  import { link } from 'svelte-navigator'
  import { createEventDispatcher } from 'svelte'
  import { fly, fade } from 'svelte/transition'
  import { userStore } from '../../store/user'

  const dispatch = createEventDispatcher()

  const toggleLeftDrawer = () => dispatch('toggleLeftDrawer')

  export let isOpen
</script>

<style>
  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50px;
    left: 0px;
    background-color: rgb(23, 42, 90, 0.6);
    z-index: 99999;
  }
  .leftDrawer {
    position: absolute;
    top: 50px;
    width: 300px;
    height: 100vh;
    background-color: #fff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    left: 0;
    z-index: 999999;
  }
  .leftDrawer a {
    padding: 15px;
    display: block;
    width: 100%;
    border-bottom: 1px solid rgba(23, 42, 90, 0.3);
    font-weight: bold;
  }
</style>

{#if isOpen}
  <div transition:fade={{ duration: 200 }} class="overlay" on:click={toggleLeftDrawer} />
  <div transition:fly={{ x: -200, duration: 200 }} class="leftDrawer">
    {#if $userStore && $userStore.role === 'admin'}
      <a href="/provider/create" use:link on:click={toggleLeftDrawer}>Create Provider</a>
      <a href="/provider" use:link on:click={toggleLeftDrawer}>Providers</a>
      <a href="/device-diagnostics" use:link on:click={toggleLeftDrawer}>Device Diagnostics</a>
    {:else}
      <a href="/customer/table" use:link on:click={toggleLeftDrawer}>Table</a>
      <a href="/customer/charts" use:link on:click={toggleLeftDrawer}>Chart</a>
      <a href="/customer/map" use:link on:click={toggleLeftDrawer}>Map</a>
    {/if}
  </div>
{/if}
