<script>
  export let primary = true
  export let secondary = false
  export let large = false
  export let disabled = false
  export let type = 'button'
</script>

<style>
  button {
    display: inline-block;
    border: none;
    border-radius: 5px;
    padding: 8px 18px 8px 18px;
    outline: none;
    cursor: pointer;
    border-bottom: 2px solid rgb(23, 42, 90);
    -webkit-transition: background-color 0.2s ease-out;
    -moz-transition: background-color 0.2s ease-out;
    -o-transition: background-color 0.2s ease-out;
    transition: background-color 0.2s ease-out;
  }

  button:hover {
    color: #fff;
    border-color: #44c400;
    background-color: #44c400;
  }

  .large {
    padding: 0.75rem 1.25rem;
  }

  .primary {
    color: #fff;
    background-color: rgb(23, 42, 90);
  }

  .secondary {
    border: 1px solid rgb(23, 42, 90);
    background-color: #fff;
    color: rgb(23, 42, 90);
  }

  button[disabled] {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
  }
</style>

<button class:large class:primary class:secondary on:click {disabled} {type}>
  <slot />
</button>
