export const firebaseConfig = {
  apiKey: 'AIzaSyAuL9HT-_fHgDlVOVGiiav6K9hC-BYMBfA',
  authDomain: 'dius-powersensor-portal-qa.firebaseapp.com',
  databaseURL: 'http://dius-powersensor-portal-qa.firebaseio.com',
  projectId: 'dius-powersensor-portal-qa',
  storageBucket: 'dius-powersensor-portal-qa.appspot.com',
  messagingSenderId: '599744484673',
  appId: '1:599744484673:web:03c9d5a23723756ee67dc0',
  measurementId: 'G-2C4CSYXMPB'
}
