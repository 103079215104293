<script>
  import { functions } from '../../firebase'
  import { pathOr } from 'ramda'
  import { DialogOverlay, DialogContent } from 'svelte-accessible-dialog'
  import { loading } from '../../store/loading'
  import FlashMessage from '../App/FlashMessage.svelte'

  const createProvider = functions.httpsCallable('createProvider')
  let successModalOpen = false

  const closeSuccessModal = () => (successModalOpen = false)
  const openSuccessModal = () => (successModalOpen = true)

  let initalState = {
    providerEmail: '',
    providerEmailError: '',
    providerName: '',
    providerNameError: '',
    APIKey: '',
    APIKeyError: '',
    sharedSecret: '',
    sharedSecretError: '',
    dataRetentionMonths: 12,
    dataRetentionMonthsError: '',
    isSubmitting: false,
    displayFlashMessage: false
  }

  let createdProvider = {
    uid: '',
    name: '',
    email: ''
  }

  let state = { ...initalState }

  const isEmailValid = () => state.providerEmail !== ''
  const emailError = (message) => {
    state.providerEmailError = message
    document.getElementsByName('providerEmail').item(0).focus()
  }

  const isAPIKeyValid = () => state.APIKey !== ''
  const apiKeyError = (message) => {
    state.APIKeyError = message
    document.getElementsByName('APIKey').item(0).focus()
  }

  const isSharedSecretValid = () => state.sharedSecret !== ''
  const sharedSecretError = (message) => {
    state.sharedSecretError = message
    document.getElementsByName('sharedSecret').item(0).focus()
  }

  const isProviderNameValid = () => state.providerName !== ''
  const providerNameError = (message) => {
    state.providerNameError = message
    document.getElementsByName('providerName').item(0).focus()
  }

  const isRetentionPeriodValid = () => state.dataRetentionMonths && state.dataRetentionMonths <= 24
  const retentionPeriodError = (message) => {
    state.dataRetentionMonthsError = message
    document.getElementsByName('dataRetentionMonths').item(0).focus()
  }

  const canSubmit = () => isEmailValid() && isAPIKeyValid() && isSharedSecretValid() && isProviderNameValid()

  const resetFlashMessageCB = () => {
    state.displayFlashMessage = false
  }

  const handleSubmit = () => {
    clearErrors()
    if (!isSharedSecretValid()) sharedSecretError('Invalid Shared Secret')
    if (!isAPIKeyValid()) apiKeyError('Invalid API Key')
    if (!isProviderNameValid()) providerNameError('Invalid Provider Name')
    if (!isEmailValid()) emailError('Invalid Provider Email')
    if (!isRetentionPeriodValid())
      retentionPeriodError('Invalid data retention period (must be present and less than 24 months)')
    if (canSubmit()) {
      loading.set(true)

      state.isSubmitting = true
      const { providerEmail, providerName, APIKey, sharedSecret, dataRetentionMonths } = state

      createProvider({ providerEmail, providerName, APIKey, sharedSecret, dataRetentionMonths })
        .then((result) => {
          console.log(result)
          createdProvider = {
            uid: result.data.uid,
            name: state.providerName,
            email: state.providerEmail
          }
          openSuccessModal()
          handleClear()
          state.displayFlashMessage = true
        })
        .catch((err) => {
          console.error(err, err.details)
          if (pathOr(false, ['details', 'code'], err)) {
            switch (err.details.code) {
              case 'auth/email-already-exists':
              case 'auth/invalid-email':
              case 'providerEmail':
                emailError(err.details.message)
                break
              case 'providerName':
                providerNameError(err.details.message)
                break
              case 'APIKey':
                apiKeyError(err.details.message)
                break
              case 'sharedSecret':
                sharedSecretError(err.details.message)
                break
            }
          }
          state.isSubmitting = false
        })
        .finally(() => {
          loading.set(false)
        })
    }
  }

  const clearErrors = () => {
    state.providerEmailError = ''
    state.APIKeyError = ''
    state.sharedSecretError = ''
    state.providerNameError = ''
    state.dataRetentionMonthsError = ''
  }

  const handleClear = () => {
    state = { ...initalState }
  }
</script>

<style>
  input {
    margin: 0 0 0px 0;
  }
  button {
    margin: 20px 10px 0 0;
  }
  .error {
    font-size: 10px;
    color: #ff0000;
  }
  .modal-actions {
    display: flex;
    flex-direction: row-reverse;
  }
  .created-values {
    display: grid;
    grid-template-columns: 10% auto;
  }
</style>

<div class="toolbar__header layout__flex_column layout__flex_container_bottom svelte__wrapper" />

<div class="box centered">
  <h1>Create Provider</h1>

  {#if state.displayFlashMessage}
    <FlashMessage cb={resetFlashMessageCB}>Provider details have been updated successfully!</FlashMessage>
  {/if}

  <label for="providerName">Provider Email</label>
  <input type="text" bind:value={state.providerEmail} name="providerEmail" placeholder="enter provider email" />
  <span class="error">{state.providerEmailError}</span>

  <label for="providerName">Provider Name</label>
  <input type="text" bind:value={state.providerName} name="providerName" placeholder="enter provider name" />
  <span class="error">{state.providerNameError}</span>

  <label for="APIKey">API Key</label>
  <input type="text" bind:value={state.APIKey} name="APIKey" placeholder="enter provider API key" />
  <span class="error">{state.APIKeyError}</span>

  <label for="sharedSecret">Shared Secret</label>
  <input type="text" bind:value={state.sharedSecret} name="sharedSecret" placeholder="enter provider shared secret" />
  <span class="error">{state.sharedSecretError}</span>

  <label for="dataRetentionMonths">Data Retention Period (months)</label>
  <input type="number" name="dataRetentionMonths" bind:value={state.dataRetentionMonths} />
  <span class="error">{state.dataRetentionMonthsError}</span>

  <div>
    <button on:click={handleClear}>Clear</button>
    <button on:click={handleSubmit} disabled={state.isSubmitting}>Create Provider</button>
  </div>
</div>

<DialogOverlay isOpen={successModalOpen} onDismiss={closeSuccessModal}>
  <DialogContent>
    <p>Provider created with the following details</p>
    <div class="created-values">
      <span>ID</span>
      <span>{createdProvider.uid}</span>
      <span>Name</span>
      <span>{createdProvider.name}</span>
      <span>Email</span>
      <span>{createdProvider.email}</span>
    </div>
    <div class="modal-actions">
      <button on:click={closeSuccessModal}>Close</button>
    </div>
  </DialogContent>
</DialogOverlay>
