<script>
  export let timestamp
  export let title

  $: timestamp = timestamp ? new Date(timestamp * 1000).toLocaleString('en-AU') : '-'
</script>

<style>
  .card {
    border: 1px solid;
    margin: 1rem 0 1rem 1rem;
    width: fit-content;
    border-radius: 2px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .timestamp {
    padding-top: 0.5rem;
    font-size: 0.6rem;
  }

  .title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .card-content {
    align-self: start;
    font-size: 0.8rem;
  }
</style>

<div class="card">
  <div class="card-content">
    <div class="title">{title}</div>
    <slot />
  </div>
  <div class="timestamp">{timestamp}</div>
</div>
