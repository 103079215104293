<script>
  import GridFilterButton from './GridFilterButton.svelte'
  import DisplayedDeviceInfo from './DisplayedDeviceInfo.svelte'
  import { onMount } from 'svelte'
  import { googleMaps } from '../../store/maps'
  import { userStore } from '../../store/user'
  import { filters } from '../../store/filters'
  import {
    setVisibilityOfGridMarkers,
    setCustomControl,
    setMarkers,
    filterCircleOnMap,
    countDevicesInArea
  } from '../../services/map'
  import { results } from '../../store/results'
  import MarkerClusterer from '@google/markerclustererplus'
  import mapStyle from './mapStyle'

  const customers = $userStore.customers
  let container
  let map
  let zoom = 10
  let center = { lat: -34.397, lng: 150.644 }
  let marker
  let fromGridChecked = true
  let toGridChecked = true
  let bounds
  let fromGridMarkers = []
  let toGridMarkers = []
  let devicesInArea = []
  let allMarkers = []
  let numberOfAllDevices = 0
  let deviceWithNoGeoLocation = 0
  let customersData = []
  let timeRangeFilter
  let fromGridMarkerLocation = {}
  let toGridMarkerLocation = {}
  let mapBounded = false
  let clusterer

  function handleGridCheckboxClick(event) {
    fromGridChecked = event.detail.fromGridChecked
    toGridChecked = event.detail.toGridChecked
  }

  function updateMap() {
    allMarkers.forEach((marker) => marker.setMap(null))
    fromGridMarkers.forEach((marker) => marker.setMap(null))
    toGridMarkers.forEach((marker) => marker.setMap(null))

    allMarkers = []
    fromGridMarkers = []
    toGridMarkers = []

    customers.map((customer) => {
      if (customer.location) {
        setMarkers(
          customer,
          $googleMaps,
          map,
          fromGridMarkers,
          toGridMarkers,
          marker,
          allMarkers,
          bounds,
          customersData,
          timeRangeFilter,
          fromGridMarkerLocation,
          toGridMarkerLocation
        )
      } else {
        deviceWithNoGeoLocation += 1
      }
    })

    if (!mapBounded) {
      map.fitBounds(bounds)
      mapBounded = true
    }

    clusterer = new MarkerClusterer(map, allMarkers, {
      minimumClusterSize: 1,
      maxZoom: 11,
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    })

    const initialZoom = map.getZoom()
    if (initialZoom < 12) {
      setVisibilityOfGridMarkers(fromGridMarkers, toGridMarkers, false)
    }

    $googleMaps.maps.event.addListener(map, 'zoom_changed', function () {
      const currentZoom = map.getZoom()
      if (currentZoom <= 11) {
        clusterer.setMinimumClusterSize(1)
        setVisibilityOfGridMarkers(fromGridMarkers, toGridMarkers, false)
      } else {
        clusterer.setMinimumClusterSize(2)
        setVisibilityOfGridMarkers(fromGridMarkers, toGridMarkers, true)
      }
    })

    $googleMaps.maps.event.addListener(map, 'bounds_changed', function () {
      devicesInArea = []
      countDevicesInArea(map, allMarkers, devicesInArea)
    })
  }

  function createMap() {
    googleMaps.subscribe((google) => {
      bounds = new google.maps.LatLngBounds()
      map = new google.maps.Map(container, {
        styles: mapStyle,
        zoom,
        disableDefaultUI: true,
        center
      })
    })

    map.controls[$googleMaps.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('customControl'))

    $googleMaps.maps.event.addListenerOnce(map, 'tilesloaded', function () {
      numberOfAllDevices = allMarkers.length
      setCustomControl()
    })
  }

  onMount(async () => {
    createMap()
  })

  results.subscribe(({ search }) => {
    customersData = search
    if (map) {
      updateMap()
    }
  })

  $: {
    timeRangeFilter = $filters.timeRangeFilter
    if (!fromGridChecked) {
      filterCircleOnMap(null, fromGridMarkers)
    }

    if (!toGridChecked) {
      filterCircleOnMap(null, toGridMarkers)
    }

    if (fromGridChecked) {
      filterCircleOnMap(map, fromGridMarkers)
    }

    if (toGridChecked) {
      filterCircleOnMap(map, toGridMarkers)
    }
  }
</script>

<style>
  .map {
    flex: 1;
  }
</style>

<div id="customControl">
  <GridFilterButton on:click={handleGridCheckboxClick} />
  <DisplayedDeviceInfo devicesInArea={devicesInArea.length} {numberOfAllDevices} {deviceWithNoGeoLocation} />
</div>
<div class="map" bind:this={container} />
