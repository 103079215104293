import RootApp from './components/App/RootApp.svelte'
import '../public/app.scss'
import { googleMaps } from './store/maps'

const app = new RootApp({
  target: document.body
})

window.initMap = function ready() {
  googleMaps.set(window.google)
}

export default app
