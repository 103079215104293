<script>
  import { createEventDispatcher } from 'svelte'
  const dispatch = createEventDispatcher()
  let fromGridChecked = true
  let toGridChecked = true

  function handleClick(event) {
    if (event.target.id == 'fromGrid') {
      fromGridChecked = event.target.checked
    }
    if (event.target.id == 'toGrid') {
      toGridChecked = event.target.checked
    }
    dispatch('click', {
      fromGridChecked,
      toGridChecked
    })
  }
</script>

<style>
  #gridFilterButtonsWrapper {
    background: white;
    margin-right: 30px;
    margin-bottom: 20px;
    margin-left: 180px;
    padding: 10px;
    display: none;
    border: 0.5px solid #c2c2c2;
    border-radius: 10px;
    width: 220px;
  }
  #gridContainer {
    margin-bottom: 10px;
    margin: 5px;
    display: flex;
    justify-content: space-between;
  }
  .gridCheckbox {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    margin: 5px;
    transform: scale(1.5);
  }
  .gridText {
    margin: auto 5px;
    font-size: 15px;
  }
  #fromGridLabel {
    height: 35px;
    width: 35px;
    background-color: #00c6ff;
    border-radius: 30px;
    border: 1px solid white;
  }
  #toGridLabel {
    height: 35px;
    width: 35px;
    background-color: #ffb300;
    border-radius: 30px;
    border: 1px solid white;
  }
</style>

<div id="gridFilterButtonsWrapper">
  <div id="gridContainer">
    <div id="fromGridLabel" />
    <b class="gridText">From Grid</b>
    <label class="gridCheckbox" on:click={handleClick}>
      <input id="fromGrid" type="checkbox" checked="checked" />
      <span class="checkmark" />
    </label>
  </div>
  <div id="gridContainer">
    <div id="toGridLabel" />
    <b class="gridText">To Grid</b>
    <label class="gridCheckbox" on:click={handleClick}>
      <input id="toGrid" type="checkbox" checked="checked" />
      <span class="checkmark" />
    </label>
  </div>
</div>
