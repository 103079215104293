<script>
  import { onDestroy } from 'svelte'
  import Chart from 'chart.js'

  import { results } from '../../store/results'
  import colors from '../../common/colors'

  let customersData = []
  let chart

  const drawChart = () => {
    if (chart) chart.destroy()
    if (!document.getElementById('bar-chart')) return

    const ctx = document.getElementById('bar-chart').getContext('2d')
    chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: customersData.map((data) => data.customerId),
        datasets: [
          {
            label: 'from grid',
            data: customersData.map((data) => data.fromgrid),
            backgroundColor: colors.blue
          },
          {
            label: 'to grid',
            data: customersData.map((data) => data.togrid),
            backgroundColor: colors.orange
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Energy (kWh)'
              },
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    })
  }

  const unsubscribe = results.subscribe(({ search }) => {
    customersData = search
    drawChart()
  })

  onDestroy(unsubscribe)
</script>

<canvas id="bar-chart" width="10" height="4" />
