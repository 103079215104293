<script>
  import { link } from 'svelte-navigator'
  import { login } from '../../auth'
  import { loading } from '../../store/loading'

  let initalState = {
    username: '',
    password: '',
    showError: false
  }

  let state = Object.assign({}, initalState)

  const resetErrors = () => {
    state.showError = false
  }

  const handleEnterKey = (e) => {
    if (e.keyCode === 13) handleLogin()
  }

  const handleLogin = () => {
    loading.set(true)
    resetErrors()
    // TODO move implementation of login into auth
    login(state.username, state.password)
      .catch((error) => {
        console.log(error)
        state.showError = true
        state.password = ''
        document.getElementById('password').focus()
      })
      .finally(() => {
        loading.set(false)
      })
  }

  const handleClear = () => {
    state = Object.assign({}, initalState)
  }
</script>

<style>
  .background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgb(33, 54, 108);
  }
  .center-image {
    position: relative;
    left: 50%;
    margin: 100px 0 0 -133px;
  }
  .centered {
    position: relative;
    left: 50%;
    margin: 45px 0 20px -250px;
  }
  .box {
    width: 500px;
    border-radius: 8px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background: #fff;
  }
  input[type='password'] {
    margin: 20px 0 0 0;
  }
  a {
    display: inline-block;
  }
  .forgot-password {
    margin-top: 20px;
  }
  .formControls {
    margin: 20px 0 20px 0;
  }
  .buttonGroup {
    float: right;
  }
  .error {
    font-size: 10px;
    color: #ff0000;
  }
  .hide {
    visibility: hidden;
  }
  button {
    margin: 20px 0 0 10px;
  }
  @media screen and (max-width: 500px) {
    .center-image {
      position: fixed;
      left: 50%;
      margin: 100px 0 0 -133px;
    }
    .centered {
      position: fixed;
      left: 50%;
      margin: 200px 0 0 -45%;
    }
    .box {
      width: 90%;
      border-radius: 8px;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
      background: #fff;
    }
    button {
      margin: 20px 0 0 10px;
    }
  }
</style>

<div class="background" />
<img class="center-image" alt="Powersenor" src="/images/powersensor.png" />

<div class="box centered">
  <h1>
    <strong>Provider Portal</strong>
  </h1>
  <input type="text" bind:value={state.username} placeholder="Enter your username" />
  <input
    id="password"
    type="password"
    bind:value={state.password}
    on:keydown={handleEnterKey}
    placeholder="Enter your password" />
  <span class="error {state.showError ? '' : 'hide'}">Incorrect username or password</span>
  <div class="formControls">
    <a class="forgot-password" href="/reset-password" use:link>Forgot password?</a>
    <div class="buttonGroup">
      <button on:click={handleClear}>Clear</button>
      <button on:click={handleLogin}>Login</button>
    </div>
  </div>
</div>
