const LOGIN_URL = 'https://auth.powersensor.dius.com.au/v1'

export async function apiLogin(username, password) {
  return post(LOGIN_URL, '/auth/admin-console', { username, password }).then(async (res) => {
    if (res.status != 200) return Promise.reject('Authentication failed')
    else {
      const body = await res.json()
      return body
    }
  })
}

async function post(baseUrl, path, body) {
  const headers = { 'content-type': 'application/json' }
  return window.fetch(`${baseUrl}${path}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  })
}
