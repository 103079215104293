import p1 from './plug-data-1'
import p2 from './plug-data-2'
import p3a from './plug-data-3a'
import p3b from './plug-data-3b'
import p3c from './plug-data-3c'
import p3d from './plug-data-3d'
import s1 from './sensor-data-1'
import s2 from './sensor-data-2'
import sEmpty from './empty-sensor-data'

export const snapshotData = {
  1: s1,
  2: s2,
  3: sEmpty,
  4: p1,
  5: p2,
  6: p3d
}

export const timeSeriesData = {
  1: [p3a, p3b, p3c, p3d]
}
