import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'

import { firebaseConfig as stagingConfig } from '../config/staging'
import { firebaseConfig as prodConfig } from '../config/prod'

let config = null
if (
  [
    'dius-powersensor-portal-prod.web.app',
    'dius-powersensor-portal-prod.firebaseapp.com',
    'provider.powersensor.com.au'
  ].includes(window.location.host)
) {
  config = prodConfig
} else {
  config = stagingConfig
}

config ? firebase.initializeApp(config) : null

export const googleAPIKey = config ? config.apiKey : null
export const firestore = firebase.firestore()
export const functions = firebase.functions()
export const auth = firebase.auth()

export default firebase
