export default {
  ha5i: {
    utc1: 1592694016,
    utc2: 1592694046,
    value: '-135657936'
  },
  pbtp: {
    utc1: 1592694016,
    utc2: 1592694016,
    value: '19045'
  },
  bles: {
    utc1: 1587438422,
    utc2: 1587438422,
    value: '353402'
  },
  fv: {
    utc1: 1582786830,
    utc2: null,
    value: '1'
  },
  pbam: {
    utc1: 1592694016,
    utc2: 1592694046,
    value: '9.436765'
  },
  upsv: {
    utc1: 1592692036,
    utc2: 1592692036,
    value: '0'
  },
  pbpd: {
    utc1: 1592694016,
    utc2: 1592694016,
    value: '-11.7773'
  },
  uptm: {
    utc1: 1592694016,
    utc2: 1592694016,
    value: '3875784'
  },
  cali: {
    utc1: 1592627326,
    utc2: 1592627326,
    value: '0'
  },
  cavg: {
    utc1: 1579751250,
    utc2: 1579751280,
    value: '9.8'
  },
  bias: {
    utc1: 1579751250,
    utc2: 1579751280,
    value: '0.93109'
  },
  vpa3: {
    utc1: 1579751250,
    utc2: 1579751280,
    value: '19.6579'
  },
  glat: {
    utc1: 1568877766,
    utc2: 1568877766,
    value: '-37.8180685'
  },
  pbts: {
    utc1: 1592694016,
    utc2: 1592694016,
    value: '3875823'
  },
  ha7i: {
    utc1: 1592694016,
    utc2: 1592694046,
    value: '-60269144'
  },
  'fwtype/plug/wroom32/rev1': {
    utc1: 1589730443,
    utc2: 1589730443,
    value: '3300'
  },
  pbws: {
    utc1: 1592694016,
    utc2: 1592694016,
    value: '38845157'
  },
  fwh: {
    utc1: 1582786830,
    utc2: null,
    value: '1'
  },
  tsus: {
    utc1: 1592694016,
    utc2: 1592694016,
    value: '1592694016'
  },
  upis: {
    utc1: 1592692036,
    utc2: 1592692036,
    value: '3300'
  },
  vph5: {
    utc1: 1579751250,
    utc2: 1579751250,
    value: '129.4958'
  },
  hwrv: {
    utc1: 1592627326,
    utc2: 1592627326,
    value: '0'
  },
  blad: {
    utc1: 1592691821,
    utc2: 1592691821,
    value: '1195195996'
  },
  vph7: {
    utc1: 1579751250,
    utc2: 1579751250,
    value: '197.309'
  },
  phvo: {
    utc1: 1592694016,
    utc2: 1592694046,
    value: '239.182'
  },
  ha7r: {
    utc1: 1592694016,
    utc2: 1592694046,
    value: '227214308'
  },
  upts: {
    utc1: 1592692036,
    utc2: 1592692036,
    value: '142131'
  },
  ptmp: {
    utc1: 1592694016,
    utc2: 1592694046,
    value: '29.443'
  },
  fa: {
    utc1: 1582786830,
    utc2: null,
    value: '1'
  },
  phwa: {
    utc1: 1592694016,
    utc2: 1592694046,
    value: '2233.552'
  },
  saco: {
    utc1: 1592630326,
    utc2: 1592630356,
    value: '-239'
  },
  phws: {
    utc1: 1592694016,
    utc2: 1592694016,
    value: '41964660'
  },
  init: {
    utc1: 1589730443,
    utc2: 1589730443,
    value: '1'
  },
  vpa5: {
    utc1: 1579751250,
    utc2: 1579751280,
    value: '-142.3295'
  },
  vph1: {
    utc1: 1579751250,
    utc2: 1579751250,
    value: '-57.1069'
  },
  tsts: {
    utc1: 1592694016,
    utc2: 1592694016,
    value: '3875824'
  },
  bler: {
    utc1: 1587438420,
    utc2: 1587438420,
    value: '5'
  },
  ha5r: {
    utc1: 1592694016,
    utc2: 1592694046,
    value: '-61788094'
  },
  upnn: {
    utc1: 1592261295,
    utc2: 1592261295,
    value: '3'
  },
  loip: {
    utc1: 1592271629,
    utc2: 1592271629,
    value: '135571648'
  },
  vpa7: {
    utc1: 1579751250,
    utc2: 1579751280,
    value: '107.055'
  },
  fw: {
    utc1: 1582786830,
    utc2: null,
    value: '1'
  },
  corr: {
    utc1: 1584515190,
    utc2: 1584515220,
    value: '1.010957'
  },
  upln: {
    utc1: 1592596231,
    utc2: 1592596231,
    value: '793'
  },
  rssi: {
    utc1: 1592694016,
    utc2: 1592694016,
    value: '-79'
  },
  pham: {
    utc1: 1592694016,
    utc2: 1592694046,
    value: '9.50316'
  },
  factors: {
    utc1: 1592021506,
    utc2: null,
    value: '{"method":"factory","aux":[{"re":1,"im":0},{"re":1,"im":0},{"re":1,"im":0}]}'
  },
  vph3: {
    utc1: 1579751250,
    utc2: 1579751250,
    value: '112.6044'
  },
  glon: {
    utc1: 1568877766,
    utc2: 1568877766,
    value: '144.9616041'
  },
  uper: {
    utc1: 1592596231,
    utc2: 1592596231,
    value: '3'
  },
  upcf: {
    utc1: 1587626341,
    utc2: 1587626341,
    value: '3300'
  },
  tsup: {
    utc1: 1592694016,
    utc2: 1592694016,
    value: '16922'
  },
  tsun: {
    utc1: 1592694016,
    utc2: 1592694016,
    value: '450'
  },
  uppt: {
    utc1: 1592692036,
    utc2: 1592692036,
    value: '0'
  },
  fcnt: {
    utc1: 1592694016,
    utc2: 1592694016,
    value: '23'
  },
  tstp: {
    utc1: 1592694016,
    utc2: 1592694016,
    value: '2802'
  },
  ha3r: {
    utc1: 1592694016,
    utc2: 1592694046,
    value: '96535631'
  },
  pbc: {
    utc1: 1592694016,
    utc2: 1592694046,
    value: '9.246292,-1.209329'
  },
  pbwa: {
    utc1: 1592694016,
    utc2: 1592694046,
    value: '2193.464'
  },
  pbvo: {
    utc1: 1592694016,
    utc2: 1592694046,
    value: '237.084'
  },
  upbu: {
    utc1: 1587568705,
    utc2: 1587568705,
    value: '1'
  },
  ha3i: {
    utc1: 1592694016,
    utc2: 1592694046,
    value: '62597813'
  },
  pbhz: {
    utc1: 1584515190,
    utc2: 1584515220,
    value: '49.992075'
  }
}
