export default {
  ha3r: {
    utc1: 1594948610,
    utc2: 1594948611,
    value: '-732100'
  },
  upnn: {
    utc1: 1593154785,
    utc2: 1593154785,
    value: '1'
  },
  tsun: {
    utc1: 1594948610,
    utc2: 1594948610,
    value: '447'
  },
  pham: {
    utc1: 1594948463,
    utc2: 1594948464,
    value: '0.09032'
  },
  upts: {
    utc1: 1594947105,
    utc2: 1594947105,
    value: '184644'
  },
  pbpd: {
    utc1: 1594948610,
    utc2: 1594948610,
    value: '7.5037'
  },
  upsv: {
    utc1: 1594947105,
    utc2: 1594947105,
    value: '0'
  },
  fa: {
    utc1: 1582686270,
    utc2: null,
    value: '1'
  },
  pbvo: {
    utc1: 1594948610,
    utc2: 1594948611,
    value: '242.128'
  },
  pbam: {
    utc1: 1594948610,
    utc2: 1594948611,
    value: '0.323095'
  },
  fw: {
    utc1: 1582686270,
    utc2: null,
    value: '1'
  },
  ha5i: {
    utc1: 1594948610,
    utc2: 1594948611,
    value: '-3533975'
  },
  hwrv: {
    utc1: 1594870506,
    utc2: 1594870506,
    value: '0'
  },
  saco: {
    utc1: 1594948610,
    utc2: 1594948611,
    value: '-567'
  },
  tsup: {
    utc1: 1594948610,
    utc2: 1594948610,
    value: '18133'
  },
  init: {
    utc1: 1594944482,
    utc2: 1594944482,
    value: '1'
  },
  cali: {
    utc1: 1594870506,
    utc2: 1594870506,
    value: '0'
  },
  upln: {
    utc1: 1594944472,
    utc2: 1594944472,
    value: '451'
  },
  fwh: {
    utc1: 1582686270,
    utc2: null,
    value: '1'
  },
  corr: {
    utc1: 1584515400,
    utc2: 1584515430,
    value: '1.002859'
  },
  tstp: {
    utc1: 1594948610,
    utc2: 1594948610,
    value: '25423'
  },
  ptmp: {
    utc1: 1594948610,
    utc2: 1594948611,
    value: '25.75'
  },
  bler: {
    utc1: 1593154836,
    utc2: 1593154836,
    value: '0'
  },
  bles: {
    utc1: 1593154837,
    utc2: 1593154837,
    value: '2'
  },
  phwa: {
    utc1: 1594948463,
    utc2: 1594948464,
    value: '4.429'
  },
  pbhz: {
    utc1: 1584515400,
    utc2: 1584515430,
    value: '49.972697'
  },
  tsts: {
    utc1: 1594948610,
    utc2: 1594948610,
    value: '164543'
  },
  uppt: {
    utc1: 1594947105,
    utc2: 1594947105,
    value: '0'
  },
  vpa3: {
    utc1: 1579751214,
    utc2: 1579751220,
    value: '1.6711'
  },
  phvo: {
    utc1: 1594948463,
    utc2: 1594948464,
    value: '241.541'
  },
  ha3i: {
    utc1: 1594948610,
    utc2: 1594948611,
    value: '945800'
  },
  phws: {
    utc1: 1594948611,
    utc2: 1594948611,
    value: '7135140'
  },
  'fwtype/plug/wroom32/rev1': {
    utc1: 1594944483,
    utc2: 1594944483,
    value: '3241'
  },
  uptm: {
    utc1: 1594948610,
    utc2: 1594948610,
    value: '164544.9'
  },
  pbts: {
    utc1: 1594948610,
    utc2: 1594948610,
    value: '164543'
  },
  rssi: {
    utc1: 1594948609,
    utc2: 1594948609,
    value: '-47'
  },
  uper: {
    utc1: 1594944472,
    utc2: 1594944472,
    value: '258'
  },
  upbu: {
    utc1: 1594944710,
    utc2: 1594944710,
    value: '7'
  },
  pbwa: {
    utc1: 1594948610,
    utc2: 1594948611,
    value: '1.931'
  },
  fcnt: {
    utc1: 1594948609,
    utc2: 1594948609,
    value: '2040'
  },
  upis: {
    utc1: 1594947105,
    utc2: 1594947105,
    value: '3241'
  },
  vpa7: {
    utc1: 1579751214,
    utc2: 1579751220,
    value: '110.8006'
  },
  ha7i: {
    utc1: 1594948610,
    utc2: 1594948611,
    value: '-1359550'
  },
  ha7r: {
    utc1: 1594948610,
    utc2: 1594948611,
    value: '5427100'
  },
  loip: {
    utc1: 1594944463,
    utc2: 1594944463,
    value: '173058240'
  },
  ha5r: {
    utc1: 1594948610,
    utc2: 1594948611,
    value: '-2485175'
  },
  blad: {
    utc1: 1594946068,
    utc2: 1594946068,
    value: '1888280419'
  },
  fv: {
    utc1: 1582686270,
    utc2: null,
    value: '1'
  },
  upcf: {
    utc1: 1592876611,
    utc2: 1592876611,
    value: '3241'
  },
  pbws: {
    utc1: 1594948610,
    utc2: 1594948610,
    value: '157016'
  },
  vpa5: {
    utc1: 1579751214,
    utc2: 1579751220,
    value: '-125.2996'
  },
  pbc: {
    utc1: 1594948610,
    utc2: 1594948611,
    value: '0.008367,0.00135'
  },
  pbtp: {
    utc1: 1594948610,
    utc2: 1594948610,
    value: '7208'
  },
  tsus: {
    utc1: 1594948610,
    utc2: 1594948610,
    value: '1594948610'
  }
}
