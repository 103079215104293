<script>
  import { onMount } from 'svelte'
  import { DateTime } from 'luxon'
  import { pathOr } from 'ramda'

  import TimePeriodButtonGroup from './TimePeriodButtonGroup.svelte'
  import Mode from './Mode.svelte'
  import CustomerFilter from './CustomerFilter.svelte'
  import CustomDateFilter from './CustomDateFilter.svelte'
  import { reloadData } from '../../common/customerData'
  import { userStore } from '../../store/user'
  import { filters } from '../../store/filters'

  export let showFilter = false

  const getProviderName = () => {
    return pathOr('Provider Name', ['providerName'], $userStore)
  }

  $: {
    const selectedDate = $filters.dateFilter ? $filters.dateFilter : DateTime.local()
    const allCustomers = $userStore && $userStore.customers ? $userStore.customers : []

    const filteredCustomers =
      $filters.customerIdFilter.length > 0
        ? allCustomers.filter(({ customerId }) => $filters.customerIdFilter.includes(customerId))
        : allCustomers

    reloadData(filteredCustomers, $filters.timeRangeFilter, selectedDate)
  }

  onMount(() => filters.resetCustomerIdFilter())
</script>

<style lang="scss">
  .provider_title {
    margin-left: 1rem;
    height: fit-content;
  }

  .bottom_row_wrapper {
    display: flex;
    justify-content: space-between;
  }

  .bottom_row_wrapper > div {
    display: inline-block;
    width: 33%;
  }

  .bottom_row_wrapper > :first-child {
    padding: 0 6rem 0 3rem;
  }

  .wrapper {
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 9rem;
    background-color: var(--primary_blue);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .wrapper > div {
    min-height: 4rem;
  }

  .baseline {
    align-items: baseline;
  }
</style>

<div class="wrapper">
  <div class="layout__flex_space_between baseline">
    <div class="typography__header_2 typography__white provider_title">
      <div class="icon-logo icon--medium" />
      &nbsp; {getProviderName()}
    </div>

    <CustomDateFilter />
  </div>

  <div class="bottom_row_wrapper">
    <div>
      {#if !showFilter}
        <CustomerFilter />
      {/if}
    </div>

    <div>
      <Mode />
    </div>

    <div>
      <TimePeriodButtonGroup />
    </div>
  </div>
</div>
