<script>
  import Select from 'svelte-select'

  import ToolBar from '../Table/ToolBar.svelte'
  import TotalsBarChart from './TotalsBarChart.svelte'
  import ToAndFromGridLineChart from './ToAndFromGridLineChart.svelte'
  import LiveChart from './LiveChart.svelte'
  import CustomerFilter from '../Table/CustomerFilter.svelte'
  import { filters } from '../../store/filters'

  const realTime = 'Real Time'
  const historical = 'Historical'
  let chartType = realTime

  const items = ['Time series interval data', 'Total Generations And Consumption']
  let selectedValue = items[0]

  const handleClear = () => (selectedValue = items[0])
</script>

<style>
  .container {
    padding: 14rem 20rem 2rem;
  }

  .chart-category-controls {
    display: flex;
  }

  .chart-category-controls > label {
    margin-right: 1rem;
  }

  .chart-options-controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    padding: 2rem 0;
  }

  .select-customer-prompt {
    text-align: center;
  }
</style>

<ToolBar showFilter={true} />
<div class="container">
  <div class="chart-category-controls">
    <label>
      <input type="radio" bind:group={chartType} value={realTime} />
      {realTime}
    </label>
    <label>
      <input type="radio" bind:group={chartType} value={historical} />
      {historical}
    </label>
  </div>

  <div class="chart-options-controls">
    <div>
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label>Customer ID</label>
      <CustomerFilter isMulti={true} />
    </div>
    {#if chartType === historical}
      <div>
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label>Chart Type</label>
        <Select {items} bind:selectedValue on:clear={handleClear} />
      </div>
    {/if}
  </div>
  {#if $filters.customerIdFilter.length > 0}
    {#if items[0] === selectedValue.value && chartType === historical}
      <ToAndFromGridLineChart />
    {:else if items[1] === selectedValue.value && chartType === historical}
      <TotalsBarChart />
    {:else if chartType === realTime}
      {#each $filters.customerIdFilter as selectedCustomer, i (selectedCustomer)}
        <LiveChart {selectedCustomer} index={i} />
      {/each}
    {/if}
  {:else}
    <h2 class="select-customer-prompt">Please select a customer ID</h2>
  {/if}
</div>
