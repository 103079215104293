export const downloadCSV = (csvData, fileName) => {
  const blob = new Blob([csvData])

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName)
  } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    const hiddenLink = window.document.createElement('a')
    hiddenLink.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData)
    hiddenLink.target = '_blank'
    hiddenLink.download = fileName
    hiddenLink.click()
  } else {
    const hiddenLink = document.createElement('a')
    if (hiddenLink.download !== undefined) {
      var url = URL.createObjectURL(blob)
      hiddenLink.setAttribute('href', url)
      hiddenLink.setAttribute('download', fileName)
      hiddenLink.style.visibility = 'hidden'
      document.body.appendChild(hiddenLink)
      hiddenLink.click()
      document.body.removeChild(hiddenLink)
    }
  }
}
