<script>
  import { logOut } from '../../auth'
  import { userStore } from '../../store/user'
  import MenuButton from './MenuButton.svelte'
  import LeftDrawer from '../LeftDrawer/LeftDrawer.svelte'
  import LogoHorizontal from './LogoHorizontal.svelte'
  let isOpen = false

  const handleToggleLeftDrawer = () => {
    isOpen = !isOpen
    document.body.style.overflowY = isOpen ? 'hidden' : 'initial'
  }

  const handleLogout = () => logOut()
</script>

<style>
  .appBar {
    height: 50px;
    /* TODO: extract color */
    background-color: rgb(23, 42, 90);
    z-index: 999;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  .right {
    height: 25px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 7px 0 0 0;
  }
  .username {
    color: #fff;
  }
  .logOutButton,
  .logOutButton:active,
  button:hover,
  button:not(:disabled):hover {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color: rgb(201, 207, 219);
  }
</style>

<header class="appBar">
  <MenuButton on:toggleLeftDrawer={handleToggleLeftDrawer} {isOpen} />
  <LogoHorizontal />
  <div class="right">
    <span class="username">{$userStore ? $userStore.email : 'Please Sign in'}</span>
    <button class="logOutButton" on:click={handleLogout}>Sign out</button>
  </div>
  <LeftDrawer on:toggleLeftDrawer={handleToggleLeftDrawer} {isOpen} />
</header>
