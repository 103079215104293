<script>
  import { onMount, onDestroy } from 'svelte'
  import { link, useNavigate } from 'svelte-navigator'
  import { filter, map, addIndex, pathOr } from 'ramda'
  import SvelteTable from 'svelte-table'

  import { firestore } from '../../firebase'
  import { userStore } from '../../store/user'

  const navigate = useNavigate()

  let rows = []
  let unsubUsersCollection
  let unsubUsersStore

  const mapIndexed = addIndex(map)

  const columns = [
    {
      key: 'edit',
      title: '',
      value: (value) => `<label class="html__checkbox-container">
                <input type="checkbox" ${value.edit ? 'checked="checked"' : ''} />
                <span class="html__checkmark"></span>
        </label>`
    },
    {
      key: 'providerName',
      title: 'Provider Name',
      value: (value) => value.providerName,
      sortable: true
    },
    {
      key: 'solarplatformAPIKey',
      title: 'Solar Platform API Key',
      value: (value) => value.solarplatformAPIKey,
      sortable: true
    },
    {
      key: 'solarplatformAPISharedSecret',
      title: 'Solar Platform API Shared Secret',
      value: (value) => value.solarplatformAPISharedSecret,
      sortable: true
    },
    {
      key: 'customers',
      title: 'Customers',
      value: (value) => `<a href="#">Show ${value.customers ? value.customers.length : 0} customers</a>`,
      sortable: true
    }
  ]

  const isProvider = (user) => pathOr('', ['role'], user) === 'provider'

  const handleCellClick = (event) => {
    rows[event.detail.row.index].edit = !rows[event.detail.row.index].edit
    if (event.detail.key == 'customers') {
      navigate(`/provider/${event.detail.row.id}/customer`)
    } else {
      navigate(`/provider/${event.detail.row.id}`)
    }
  }

  const extractData = (user) => ({ ...user.data(), id: user.id })

  const formatProviderRow = (user, index) => ({
    index,
    edit: false,
    ...user
  })

  const providersLoaded = (querySnapshot) => {
    rows = mapIndexed(formatProviderRow, filter(isProvider, map(extractData, querySnapshot.docs)))
  }

  unsubUsersStore = userStore.subscribe((user) => {
    if (!user) unsubUsersCollection()
  })

  onMount(async () => {
    unsubUsersCollection = await firestore.collection('users').onSnapshot(providersLoaded)
  })

  onDestroy(unsubUsersStore)
</script>

<style>
  .toolbar {
    margin-top: 50px;
    padding: 15px;
  }
</style>

<div class="toolbar">
  <a href="/provider/create" class="btn outline" use:link>Create Provider</a>
  <h1>Providers</h1>
</div>

<SvelteTable
  {columns}
  {rows}
  on:clickCell={handleCellClick}
  classNameThead={'tableThead'}
  classNameTbody={'tableTbody'}
  classNameRow={'pointer'} />
