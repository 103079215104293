<script>
  import { getContext } from 'svelte'
  import SvelteTable from 'svelte-table'
  import { DateTime } from 'luxon'
  import debounce from 'lodash.debounce'

  import { filters } from '../../store/filters'
  import { exportData } from '../../store/exportData'
  import { CustomerTableColumns } from './Constants'
  import Aggregate from './Aggregate.svelte'
  import ToolBar from './ToolBar.svelte'
  import SelectAllCheckbox from './SelectAllCheckbox.svelte'
  import ExportCSVModal from './ExportCSVModal.svelte'
  import Button from '../../common/components/Button.svelte'
  import { results } from '../../store/results'

  const columns = CustomerTableColumns
  let rows = []
  let endDate

  $: {
    endDate = $filters.dateFilter ? $filters.dateFilter : DateTime.local()
    rows = $results.search
  }

  const toggleCheckbox = ({ detail: { row } }) => {
    const checkbox = document.getElementById(row.id)
    checkbox.checked = !checkbox.checked
    checkbox.checked ? exportData.upsertRow(row) : exportData.removeRow(row)
  }

  const { open } = getContext('simple-modal')

  const showExportCSVModal = () => {
    open(ExportCSVModal, { selectedDate: endDate })
  }
</script>

<style lang="scss">
  .container {
    padding: 2rem;
    padding: 14rem 2rem 2rem;
  }

  .button_group_padding {
    padding: 1.5rem;
  }

  .empty_space {
    margin: 0 1rem;
    border-right: 1px solid var(--primary_gray);
    min-width: 1rem;
  }
</style>

<ToolBar />
<div class="container">
  <Aggregate />

  <div class="layout__flex button_group_padding">
    <SelectAllCheckbox allRows={rows} />
    <span class="empty_space" />
    <Button secondary large disabled={$exportData.exportRows.length === 0} on:click={showExportCSVModal}>
      EXPORT CSV
    </Button>
  </div>

  <SvelteTable
    {columns}
    {rows}
    classNameThead={'tableThead'}
    classNameTbody={'tableTbody'}
    classNameTable={'customerDataTable'}
    on:clickRow={debounce(toggleCheckbox, 300, { leading: true, trailing: false })} />
</div>
