<script>
  import Select from 'svelte-select'
  import { onMount } from 'svelte'

  import { userStore } from '../../store/user'
  import { filters } from '../../store/filters'
  import Icon from './SearchIcon.svelte'

  export let isMulti = false

  const items =
    $userStore && $userStore.customers
      ? $userStore.customers.map((customer) => ({ value: customer.customerId, label: customer.customerId }))
      : []

  let selectedValue = null

  const handleSelect = (customerId) => {
    let filterData = []
    if (typeof customerId === 'string') {
      filterData.push(customerId)
    } else {
      if (isMulti && selectedValue) filterData = selectedValue.map((entry) => entry.value)
      if (!isMulti) filterData = [selectedValue.value]
    }

    filters.updateCustomerIdFilter(filterData)
  }

  const resetData = () => filters.resetCustomerIdFilter()

  onMount(() => {
    const urlParams = new URLSearchParams(location.search)
    if (urlParams.get('customerId')) {
      handleSelect(urlParams.get('customerId'))
    }
  })
</script>

<style>
  .themed {
    --multiSelectPadding: 0 60px 0 16px;
  }
</style>

<div class="themed">
  <Select
    {items}
    {Icon}
    on:select={handleSelect}
    bind:selectedValue
    on:clear={resetData}
    {isMulti}
    placeholder={'Select a customer...'} />
</div>
