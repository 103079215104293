<script>
  import Card from '../Card.svelte'
  export let deviceData = []

  const getAlarmStatusColor = (value) => {
    const alarmStatus = getRarsAlarmStatus(value)
    if (alarmStatus === 'OK') {
      return 'green'
    } else if (alarmStatus === '?') {
      return 'orange'
    } else {
      return 'red'
    }
  }

  const getRarsAlarmStatus = (value) => {
    if (-60 >= value && value >= -90) {
      return 'OK'
    } else if (-90 > value && value > -100) {
      return '?'
    } else if (value > -100) {
      return 'X'
    }
  }

  $: rasi = deviceData.find((data) => data.tag === 'rasi') || {}
  $: rase = deviceData.find((data) => data.tag === 'rase') || {}
  $: rasu = deviceData.find((data) => data.tag === 'rasu') || {}
  $: rafs = deviceData.find((data) => data.tag === 'rafs') || {}
  $: rafu = deviceData.find((data) => data.tag === 'rafu') || {}
  $: rauc = deviceData.find((data) => data.tag === 'rauc') || {}
  $: rars = deviceData.find((data) => data.tag === 'rars') || {}
</script>

<Card timestamp={rasi.utc2 || rasi.utc1} title="BLE">
  <div>
    {`Signal strength (rars):`}
    {#if rars.value}
      <strong>{`${rars.value}dB`}</strong>
      -
      <span style={`color: ${getAlarmStatusColor(rars.value)}`}>{getRarsAlarmStatus(rars.value)}</span>
    {:else}unknown{/if}
  </div>
  <div>
    Are rars & rasi timestamps equal:
    {#if rars.utc1 && rasi.utc1}
      <span style={`color: ${rars.utc1 === rasi.utc1 ? 'green' : 'red'}`}>
        {`${rars.utc1 === rasi.utc1 ? 'YES' : 'NO'}`}
      </span>
    {:else}unknown{/if}
  </div>
  <div>
    {`Energy budget for connection (rauc):`}
    {#if rauc.value}
      <strong>{`${rauc.value}`}</strong>
    {:else}unknown{/if}
  </div>
  <div>
    {`Samples available for transfer (rasi):`}
    {#if rasi.value}
      <strong>{`${rasi.value}`}</strong>
    {:else}unknown{/if}
  </div>
  <div>
    {`Transfers attempted (rase, rafs):`}
    {#if rase.value && rafs.value}
      <strong>{`${rase.value}, ${rafs.value}`}</strong>
    {:else}unknown{/if}
  </div>
  <div>
    {`Transfers succeeded (rasu, rafu):`}
    {#if rasu.value && rafu.value}
      <strong>{`${rasu.value}, ${rafu.value}`}</strong>
    {:else}unknown{/if}
  </div>
  <div>
    Samples actually sent via BLE (flakiness of BLE link):
    {#if rasi.value && rase.value}
      <span style={`color: ${(rasi.value / rase.value) * 100 < 100 ? 'orange' : 'green'}`}>
        <strong>{`${((rasi.value / rase.value) * 100).toFixed(2)}%`}</strong>
      </span>
    {:else}unknown{/if}
  </div>
  <div>
    Percentage of samples successfully sent (user live view experience):
    {#if rasu.value && rase.value}
      <span style={`color: ${(rasu.value / rase.value) * 100 < 100 ? 'orange' : 'green'}`}>
        <strong>{`${((rasu.value / rase.value) * 100).toFixed(2)}%`}</strong>
      </span>
    {:else}unknown{/if}
  </div>
  <div>
    Percentage of successful send attempts (BLE reliability):
    {#if rasu.value && rafu.value && rase.value && rafs.value}
      <span
        style={`color: ${((rasu.value + rafu.value) / (rase.value + rafs.value)) * 100 < 100 ? 'orange' : 'green'}`}>
        <strong>{`${(((rasu.value + rafu.value) / (rase.value + rafs.value)) * 100).toFixed(2)}%`}</strong>
      </span>
    {:else}unknown{/if}
  </div>
</Card>
