<script>
  import { Router, Route } from 'svelte-navigator'
  import Modal from 'svelte-simple-modal'
  import AppBar from './AppBar.svelte'
  import Provider from '../Provider/Provider.svelte'
  import CreateProvider from '../Provider/CreateProvider.svelte'
  import EditProvider from '../Provider/EditProvider.svelte'
  import EditProviderCustomer from '../Provider/EditProviderCustomer.svelte'
  import Customer from '../Table/CustomersTable.svelte'
  import MapPage from '../Map/MapPage.svelte'
  import ChartsPage from '../Charts/ChartsPage.svelte'
  import DeviceDashboard from '../DeviceDashboard/DeviceDashboard.svelte'
  import { userStore } from '../../store/user'
</script>

<AppBar />
<main>
  <Modal>
    <Router>
      {#if $userStore && $userStore.role === 'admin'}
        <!-- Admin routes -->
        <Route path="/device-diagnostics" component={DeviceDashboard} />
        <Route path="/provider" component={Provider} />
        <Route path="/provider/create" component={CreateProvider} />
        <Route path="/provider/:providerId" component={EditProvider} />
        <Route path="/provider/:providerId/customer" component={EditProviderCustomer} />
        <!-- Default route -->
        <Route component={Provider} />
      {:else}
        <!-- Provider routes -->
        <Route path="/customer" component={Customer} />
        <Route path="/customer/table" component={Customer} />
        <Route path="/customer/map" component={MapPage} />
        <Route path="/customer/charts" component={ChartsPage} />

        <!-- Default route -->
        <Route component={Customer} />
      {/if}
    </Router>
  </Modal>
</main>
