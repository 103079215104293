<script>
  import Spinner from 'svelte-spinner'
  import { slide } from 'svelte/transition'

  export let busy
</script>

<style>
  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.5;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    transition: opacity 200ms ease-in-out;
  }
</style>

{#if busy}
  <div class="wrapper">
    <div transition:slide>
      <Spinner size="200" speed="750" color="#A82124" thickness="2" gap="40" />
    </div>
  </div>
{/if}
