import { writable } from 'svelte/store'
import { clone } from 'ramda'

const createFilterStore = () => {
  const { subscribe, update } = writable({
    exportRows: []
  })

  return {
    subscribe,
    upsertAllRows: (rows) =>
      update((exportData) => {
        return {
          ...exportData,
          exportRows: rows
        }
      }),

    clear: () =>
      update((exportData) => {
        return {
          ...exportData,
          exportRows: []
        }
      }),

    upsertRow: (row) =>
      update((exportData) => {
        if (exportData.exportRows && !exportData.exportRows.includes(row)) {
          const exportRows = [...exportData.exportRows, row]
          return { ...exportData, exportRows }
        }
        return exportData
      }),
    removeRow: (row) =>
      update((exportData) => {
        const cloneData = clone(exportData.exportRows)
        const exportRows = cloneData.filter(({ id }) => id !== row.id)
        return { ...exportData, exportRows }
      })
  }
}

export const exportData = createFilterStore()
