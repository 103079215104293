import axios from 'axios'

export const getDiagnosticsForDeviceId = async (token, adminUrl, deviceId) => {
  try {
    const { data } = await axios.get(`${adminUrl}/v2/device/${deviceId}/diagnostics`, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })

    return data
  } catch (error) {
    console.error(`Error fetching diagnostics for device '${deviceId}'`, error)
  }
}
