<script>
  import { DateTime } from 'luxon'
  import Color from 'color'
  import Chart from 'chart.js'

  import { filters } from '../../store/filters'
  import { userStore } from '../../store/user'
  import { chartData } from '../../common/customerData'
  import colors from '../../common/colors'
  import { maxEntriesFor } from './helpers'

  let chart
  let data = []

  $: {
    const selectedDate = $filters.dateFilter ? $filters.dateFilter : DateTime.local()
    const allCustomers = $userStore && $userStore.customers ? $userStore.customers : []

    const filteredCustomers =
      $filters.customerIdFilter.length > 0
        ? allCustomers.filter(({ customerId }) => $filters.customerIdFilter.includes(customerId))
        : allCustomers

    chartData(filteredCustomers, $filters.timeRangeFilter, selectedDate).then((newData) => {
      data = newData
      drawChart()
    })
  }

  const drawChart = () => {
    if (chart) chart.destroy()
    if (!document.getElementById('line-chart')) return

    const ctx = document.getElementById('line-chart').getContext('2d')

    const datasets = data
      .map(({ customer, data }) => [
        {
          label: `${customer.customerId} - from grid`,
          backgroundColor: Color(colors.blue).alpha(0.6).string(),
          borderColor: Color(colors.blue).alpha(0.6).string(),
          data: data.map(({ fromgrid }) => fromgrid),
          fill: false
        },
        {
          label: `${customer.customerId} - to grid`,
          backgroundColor: Color(colors.orange).alpha(0.6).string(),
          borderColor: Color(colors.orange).alpha(0.6).string(),
          data: data.map(({ togrid }) => togrid),
          fill: false
        },
        {
          label: `${customer.customerId} - total consumption`,
          backgroundColor: Color(colors.darkred).alpha(0.6).string(),
          borderColor: Color(colors.darkred).alpha(0.6).string(),
          data: data.map(({ household }) => household),
          fill: false
        }
      ])
      .flat()

    const labels = [...Array(maxEntriesFor(data)).keys()].map((idx) =>
      DateTime.local()
        .startOf('day')
        .plus({ minutes: idx * 30 })
        .toFormat('HH:mm')
    )

    const config = {
      type: 'line',
      data: {
        labels,
        datasets
      },
      options: {
        responsive: true,
        tooltips: {
          mode: 'index',
          intersect: false
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Time'
              }
            }
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Energy (kWh)'
              }
            }
          ]
        }
      }
    }

    chart = new Chart(ctx, config)
  }
</script>

<canvas id="line-chart" width="10" height="4" />
