export default {
  tscu: { utc1: 1576650601, utc2: 1576650601, value: '444' },
  satp: { utc1: 1595470563, utc2: 1595470564, value: '15715' },
  am05: { utc1: 1595470110, utc2: 1595470410, value: '-8594' },
  role: { utc1: 1595469779, utc2: 1595469779, value: '0' },
  buof: { utc1: 1595468199, utc2: 1595469999, value: '75302250' },
  tsus: { utc1: 1595470438, utc2: 1595470438, value: '1595470438' },
  raev: { utc1: 1580374230, utc2: 1580374260, value: '112' },
  am03: { utc1: 1595470110, utc2: 1595470410, value: '4211' },
  wscl: { utc1: 1595466271, utc2: 1595467981, value: '12' },
  e00: { utc1: 1595470563, utc2: 1595470564, value: '1895825949,-1395' },
  tsdr: { utc1: 1595470464, utc2: 1595470464, value: '5061' },
  rasi: { utc1: 1595469825, utc2: 1595470337, value: '512' },
  tsca: { utc1: 1595470464, utc2: 1595470464, value: '0' },
  dnss: { utc1: 1595470588, utc2: 1595470589, value: '33728704' },
  tsup: { utc1: 1595470438, utc2: 1595470438, value: '27019' },
  m03: { utc1: 1595470563, utc2: 1595470564, value: '-2053.93,1000.95' },
  tssr: { utc1: 1576650601, utc2: 1576650601, value: '5261047' },
  tsso: { utc1: 1595470464, utc2: 1595470464, value: '1' },
  rars: { utc1: 1595469825, utc2: 1595470337, value: '-61' },
  racb: { utc1: 1576650284, utc2: 1576650285, value: '0' },
  kvcc: { utc1: 1595466270, utc2: 1595467980, value: '3331310' },
  f04: { utc1: 1584424800, utc2: null, value: '0.000896265,0.000110069' },
  upis: { utc1: 1595470438, utc2: 1595470439, value: '3239' },
  nwku: { utc1: 1595470588, utc2: 1595470589, value: '0.362481' },
  m02: { utc1: 1595470563, utc2: 1595470564, value: '28368.97,-7011.29' },
  batt: { utc1: 1595469779, utc2: 1595469780, value: '4.133789' },
  wsch: { utc1: 1595466271, utc2: 1595467981, value: '11' },
  racr: { utc1: 1576650279, utc2: 1576650280, value: '46' },
  racs: { utc1: 1576650279, utc2: 1576650280, value: '2' },
  rase: { utc1: 1595469825, utc2: 1595470337, value: '562' },
  tscr: { utc1: 1576650601, utc2: 1576650601, value: '12780' },
  am01: { utc1: 1595470110, utc2: 1595470410, value: '6646' },
  sats: { utc1: 1595470563, utc2: 1595470564, value: '88771' },
  wspl: { utc1: 1595466271, utc2: 1595467981, value: '1.24501' },
  m04: { utc1: 1595470563, utc2: 1595470564, value: '416.35,-185.14' },
  ha9i: { utc1: 1592292570, utc2: 1592292600, value: '-6303094' },
  rauc: { utc1: 1595469825, utc2: 1595470337, value: '1535625' },
  imag: { utc1: 1595381817, utc2: 1595381817, value: '820' },
  m01: { utc1: 1595470563, utc2: 1595470564, value: '1092.65,-429.01' },
  tspr: { utc1: 1595470588, utc2: 1595470589, value: '22.539' },
  rafu: { utc1: 1595469825, utc2: 1595470337, value: '22' },
  bufa: { utc1: 1595468199, utc2: 1595469999, value: '105124108' },
  ha3i: { utc1: 1592292570, utc2: 1592292600, value: '1242182' },
  am04: { utc1: 1595470110, utc2: 1595470410, value: '6350' },
  ha7r: { utc1: 1592292570, utc2: 1592292600, value: '-13214212' },
  blea: { utc1: 1580374230, utc2: 1580374260, value: '1' },
  ha3r: { utc1: 1592292570, utc2: 1592292600, value: '16167614' },
  wsph: { utc1: 1595466271, utc2: 1595467981, value: '11.436036' },
  bues: { utc1: 1595468199, utc2: 1595469999, value: '19066003' },
  upup: { utc1: 1592292570, utc2: 1592292571, value: '3239' },
  tsts: { utc1: 1595470438, utc2: 1595470438, value: '88647' },
  bups: { utc1: 1595469998, utc2: 1595469998, value: '5460' },
  budi: { utc1: 1595468199, utc2: 1595469999, value: '114948000' },
  tsch: { utc1: 1595470464, utc2: 1595470464, value: '0' },
  habi: { utc1: 1592292570, utc2: 1592292600, value: '-2129965' },
  busa: { utc1: 1595468199, utc2: 1595469999, value: '15120000' },
  bura: { utc1: 1595468199, utc2: 1595469999, value: '80349120' },
  ae00: { utc1: 1580785290, utc2: 1580785590, value: '8' },
  ptji: { utc1: 1595470563, utc2: 1595470564, value: '0.032596' },
  ptms: { utc1: 1595470563, utc2: 1595470564, value: '19.99524' },
  am02: { utc1: 1595470110, utc2: 1595470410, value: '-2590' },
  samc: { utc1: 1595470563, utc2: 1595470564, value: '4172223' },
  ha7i: { utc1: 1592292570, utc2: 1592292600, value: '-1267770' },
  sahp: { utc1: 1595470563, utc2: 1595470564, value: '65410' },
  tsof: { utc1: 1595470464, utc2: 1595470464, value: '50200' },
  f05: { utc1: 1584424800, utc2: null, value: '-0.00490658,-0.00017146' },
  am00: { utc1: 1595470110, utc2: 1595470410, value: '-4807' },
  ae01: { utc1: 1580785290, utc2: 1580785590, value: '18' },
  upld: { utc1: 1595470438, utc2: 1595470439, value: '0.325644' },
  racf: { utc1: 1576650279, utc2: 1576650280, value: '2' },
  ha5i: { utc1: 1592292570, utc2: 1592292600, value: '12525296' },
  m05: { utc1: 1595470563, utc2: 1595470564, value: '26800.39,-7010.8' },
  rntm: { utc1: 1595470438, utc2: 1595470439, value: '0.88727' },
  busp: { utc1: 1595381799, utc2: 1595383599, value: '36920605' },
  tssu: { utc1: 1576650601, utc2: 1576650601, value: '1576650601' },
  'fwtype/sensor/rev6/hw0/kl64': { utc1: 1595381798, utc2: 1595381798, value: '3239' },
  upsl: { utc1: 1595470438, utc2: 1595470439, value: '0' },
  m00: { utc1: 1595470563, utc2: 1595470564, value: '-9307.92,3927.56' },
  budg: { utc1: 1595469998, utc2: 1595469998, value: '178024' },
  f00: { utc1: 1584424800, utc2: null, value: '0.00754739,0.00135818' },
  rasu: { utc1: 1595469825, utc2: 1595470337, value: '512' },
  rafs: { utc1: 1595469825, utc2: 1595470337, value: '23' },
  racu: { utc1: 1576650279, utc2: 1576650280, value: '4' },
  tsr2: { utc1: 1595470588, utc2: 1595470589, value: '19.266' },
  stmo: { utc1: 1594983200, utc2: 1594983201, value: '3' },
  habr: { utc1: 1592292570, utc2: 1592292600, value: '1427603' },
  irsi: { utc1: 1595381816, utc2: 1595381817, value: '-77' },
  ha5r: { utc1: 1592292570, utc2: 1592292600, value: '8788382' },
  factors: {
    utc1: 1594855680,
    utc2: null,
    value:
      '{"method":"basic+phcorr_harmonic1_5_7_sahp","mag":[{"re":0.00578724,"im":0.00113823},{"re":-0.000840299,"im":-0.000117003},{"re":-0.012402,"im":-0.000634087},{"re":-0.00117771,"im":-0.00023163},{"re":0.000214083,"im":0.0000298088},{"re":-0.00982961,"im":-0.000502568}],"aux":[{"re":240,"im":0},{"re":-0.652172,"im":0},{"re":0,"im":0},{"re":-0.223267,"im":0},{"re":-0.419767,"im":0},{"re":0,"im":0}],"private":[]}'
  },
  ivol: { utc1: 1595381817, utc2: 1595381817, value: '619' },
  ha9r: { utc1: 1592292570, utc2: 1592292600, value: '-688224' },
  f01: { utc1: 1584424800, utc2: null, value: '-0.00117675,-0.000144515' },
  woui: { utc1: 1595470588, utc2: 1595470589, value: '15769538' },
  f03: { utc1: 1584424800, utc2: null, value: '-0.00435926,-0.000784464' },
  phym: { utc1: 1595470588, utc2: 1595470589, value: '3' },
  chnl: { utc1: 1595470588, utc2: 1595470589, value: '11' },
  tsr1: { utc1: 1595470588, utc2: 1595470589, value: '26.782' },
  nwkc: { utc1: 1595470588, utc2: 1595470589, value: '0.315205' },
  rapa: { utc1: 1577157750, utc2: 1577157780, value: '1388974592' },
  f02: { utc1: 1584424800, utc2: null, value: '-0.0101116,-0.000353348' },
  phyf: { utc1: 1594983181, utc2: 1594983182, value: '3' },
  rssi: { utc1: 1595470588, utc2: 1595470589, value: '-67' },
  ktmp: { utc1: 1595470563, utc2: 1595470564, value: '16.999' },
  rafe: { utc1: 1576649729, utc2: 1576650241, value: '58' },
  '': { utc1: 1576650360, utc2: 1576650360, value: '1388974592' },
  klpn: { utc1: 1570614360, utc2: 1570614361, value: '15' },
  klra: { utc1: 1570614360, utc2: 1570614361, value: '8192' },
  tsun: { utc1: 1595470438, utc2: 1595470438, value: '360' },
  watt: { utc1: 1595470530, utc2: 1595470560, value: '-679' },
  upct: { utc1: 1595470438, utc2: 1595470439, value: '16' },
  klmd: { utc1: 1570614360, utc2: 1570614361, value: '116' },
  upau: { utc1: 1592292603, utc2: 1592292604, value: '0' },
  bupu: { utc1: 1595469998, utc2: 1595469998, value: '1512768' },
  buca: { utc1: 1595468199, utc2: 1595469999, value: '105120000' },
  saco: { utc1: 1595470563, utc2: 1595470564, value: '-273' },
  e01: { utc1: 1595470563, utc2: 1595470564, value: '4621024,1140306' },
  tstp: { utc1: 1595470438, utc2: 1595470438, value: '9568' },
  klrv: { utc1: 1570614360, utc2: 1570614361, value: '9' }
}
